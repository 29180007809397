@import "../../styles/utils";

.versicherte-page-list {
  margin-top: 6.4rem;

  &.no-action-buttons {
    margin-top: calc(6.4rem + 6.2rem);
  }

  @include tablet {
    margin: 0;
  }
}

.disabled-create-insurant-button {
  cursor: not-allowed !important;
  color: $medium-grey !important;
  &:after, &:before {
    display: none;
  }
  svg {
    circle, line {
      stroke: $medium-grey;
    }
  }
}

.disabled-delete-import-button {
  svg {
    polyline, line, path {
      stroke: $medium-grey;
    }
  }
}

.buttonLink {
  margin-top: 3rem;
  height: auto;
  max-height: 36px;

  @media (max-width: 991px) {
    margin-top: 28px;
  }
}

button.mdc-tab {
  padding: 0;
  align-self: flex-end;
  height: 60px;
}

.entity {
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 17px;
}


.mdc-tab__content {
  // width: 77px;
  color: var(--main);
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  letter-spacing: 0;
  // text-align: right;
}

.stepIndicator {
  margin-left: auto;
  white-space: nowrap;
  @include tablet {
    margin-bottom: 3.2rem;
  }
}

.showChildOnHover {
  .showable {
    visibility: hidden;
  }

  &:hover {
    .showable {
      visibility: visible;
    }
  }
}

.payrollTable {
  @media (max-width: 792px) {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: block;

          td {
            padding: 10px !important;

            span {
              display: block !important;
            }
          }

          td:nth-child(1) {
          }

          td:nth-child(2) {
          }

          td:nth-child(3) {
            display: inline-block;
            padding-bottom: 25px !important;
          }

          td:nth-child(4) {
            display: inline-block;
            padding-bottom: 25px !important;
          }

          td:nth-child(5) {
            display: inline-block;
            padding-bottom: 25px !important;
          }

          td:nth-child(6) {
            float: right;
            margin-top: -90px;

            div > button:first-child {
              display: block !important;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 456px) {
    table {
      tbody {
        tr {
          // td {
          //     padding: 5px !important;

          //     span {
          //         font-size: 1.1rem !important;
          //     }
          // }

          td:nth-child(1) {
            padding-left: 12px !important;
          }

          td:nth-child(3) {
            padding-left: 12px !important;
          }
          td:nth-child(6) {
            margin-top: -162px !important;
          }
        }
      }
    }
  }

  // @media (max-width: 342px) {
  //     table {
  //         tbody {
  //             tr {
  //                 td:nth-child(6) {
  //                     margin-top: -174px !important;
  //                 }
  //             }
  //         }
  //     }
  // }
}

.addressTable {
  @media (max-width: 792px) {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: block;

          td {
            padding: 10px !important;

            span {
              display: block !important;
            }
          }

          td:nth-child(1) {
          }

          td:nth-child(2) {
          }

          td:nth-child(3) {
            display: inline-block;
            padding-bottom: 10px !important;
          }

          td:nth-child(4) {
            display: inline-block;
            padding-bottom: 10px !important;
          }

          td:nth-child(5) {
            padding-bottom: 12px !important;
          }

          td:nth-child(6) {
            padding-bottom: 12px !important;
          }

          td:nth-child(7) {
            padding-bottom: 12px !important;
          }

          td:nth-child(8) {
            float: right;
            margin-top: -245px;

            div > button:first-child {
              display: block !important;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  //       @media (max-width: 443px) {
  //         table {
  //           tbody {
  //             tr {
  //               td:nth-child(8) {
  //                 margin-top: -174px !important;
  //               }
  //             }
  //           }
  //         }
  //       }
}

.unpaidVacationTable {
  table-layout: fixed;
  tbody {
    tr {
      td:nth-child(1) {
        max-width: 120px;
      }
      td:nth-child(2) {
        max-width: 120px;
      }
      td:nth-child(3) {
        max-width: 231px;
      }
      td:nth-child(4) {
        max-width: 231px;
      }
    }

    td:not(:last-child) {
      text-overflow: unset;
      overflow: visible;
      white-space: unset;
    }
  }
}

.form-title {
  color: #263238;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 16px;

  margin-top: 35px;
  margin-bottom: 30px;

  &.unpaid-vacations {
    margin-bottom: 58px;
  }
}

.form-subtitle {
  color: #00353c;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.17px;
  line-height: 20px;

  margin-top: 20px;
  margin-bottom: 24px;
}


.progressBar {
  height: 10px;
  width: 100%;
  border-radius: 8px;
  background-color: #f3f3f3;
  transition: all 0.2s linear;
  overflow: hidden;

  .fill {
    height: 10px;
    width: 0;
    border-radius: 8px;
    background-color: #c4d787;
    transition: all 0.2s linear;
  }
}
