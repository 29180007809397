@import "../../styles/utils";

// Cockpit Icon
.cockpit-link {
  svg {

    path {
      fill: $grey;
    }

    circle {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        stroke: $grey;
      }
    }
  }

  &:focus, &:hover {
    svg {
      path {
        fill: $action;
      }

      circle {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          stroke: $action;
        }
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      path {
        fill: $highlight;
      }

      circle {
        &:nth-child(1) {
          display: block;
        }

        &:nth-child(2) {
          stroke: $highlight;
        }
      }
    }
  }
}

// Mutations Icon
.mutations-link {
  svg {
    path {
      &:first-child,
      &:last-child {
        fill: $grey;
      }
    }
    polyline {
      stroke: $grey;
    }
  }

  &:focus, &:hover {
    svg {
      path {
        &:first-child,
        &:last-child {
          fill: $action;
        }
      }
      polyline {
        stroke: $action;
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      path {
        &:first-child,
        &:last-child {
          fill: $highlight;
        }
      }
      polyline {
        stroke: $highlight;
      }
    }
  }
}

// Personen Icon
.personen-link {
  svg {
    path {
      stroke: $grey;
    }
    circle {
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        stroke: $grey;
      }
      &:nth-child(6) {
        stroke: $grey;
      }
    }
  }

  &:focus, &:hover {
    svg {
      path {
        stroke: $action;
      }
      circle {
        &:nth-child(5) {
          stroke: $action;
        }
        &:nth-child(6) {
          stroke: $action;
        }
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      path {
        stroke: $highlight;
      }
      circle {
        &:nth-child(5) {
          stroke: $highlight;
        }
        &:nth-child(6) {
          stroke: $highlight;
        }
      }
    }
  }
}

.companies-link {
  svg {

  }

  &:focus, &:hover {
    svg {
      path {
        stroke: $action;
      }
      polygon {
        fill: $action;
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      path {
        stroke: $highlight;
      }
      polygon {
        fill: $highlight;
      }
    }
  }
}

.invoices-link {
  svg {
    rect, path {
      stroke: $grey;
    }
  }
  &:focus, &:hover {
    svg {
      rect, path {
        stroke: $action;
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      rect, path {
        stroke: $highlight;
      }
    }
  }
}

.filetransfer-link {
  svg {
    path {
      &:nth-child(4) {
        display: none;
      }
    }
  }
  &:focus, &:hover {
    svg {
      line {
        stroke: $action;
      }
      path {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          stroke: $action;
        }
        &:nth-child(3) {
          fill: #F3F3F3;
        }
        &:nth-child(7),
        &:nth-child(8) {
          fill: $action;
        }
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      line {
        stroke: $highlight;
      }
      path {
        &:nth-child(4) {
          display: block;
          stroke: $highlight;
        }
      }
      path {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          stroke: $highlight;
        }
        &:nth-child(3) {
          fill: #F3F3F3;
        }
        &:nth-child(7),
        &:nth-child(8) {
          fill: $highlight;
        }
      }
    }
  }
}

.profile-link {
  svg {
    path, circle {
      stroke: $grey;
    }
  }

  &:hover, &:focus {
    svg {
      path, circle {
        stroke: $action;
      }
    }
  }

  &.active {
    background-color: #F3F3F3;
    p {
      color: $highlight;
      font-weight: bold;
    }
    svg {
      path, circle {
        stroke: $highlight;
      }
    }
  }
}



















