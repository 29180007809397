.label {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  white-space: pre-line;
}

.btn-line {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 4.8rem;
}

.body-wrapper {
  padding-right: 160px;
}

.check-box-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
