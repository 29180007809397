@import "../../../../styles/utils";

.listView {
  margin-top: 6.4rem;
  padding-bottom: 12rem;

  @include tablet {
    margin-top: 4rem;
  }

  &.morePadding {
    padding-bottom: 10rem;
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  margin-bottom: 2.4rem;

  @include tablet {
    display: none;
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 150px;
    white-space: nowrap;

    &:first-child {
      max-width: 175px;
    }

    &:nth-child(2) {
      max-width: 100px;
    }

    &:nth-child(3) {
      max-width: 330px;
      display: flex;

      &> span {
        flex-basis: 50%;
        max-width: 165px;
      }
    }

    color: $grey;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.listHeaderText {
  margin-bottom: 2.4rem;
  color: $grey;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
}

.labelHeaderText {
  color: $content;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-bottom: 0.4rem;
}

.labelContentText {
  color: $content;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
}
.importActions {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: space-between;
  @include tablet {
    justify-content: flex-end;
    margin-top: 0;
  }
}


