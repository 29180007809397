@import "../../styles/utils";

.validationMessage {
   display: flex;
   align-items: center;
   //height: 18px;
   font-size: 14px;
   line-height: 17px;
   color: #AFAFB1;
   margin-top: 5px;
   position: absolute;
   top: 78px;
   font-weight: 500;
}

.empty {
   display: none;
}

.success {
   color: $verification;
}

.undefined {
   visibility: hidden;
}

.error {
   color: $error;
}

.warning {
   color: $warning;
}

.standard {
   //
}
