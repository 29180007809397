@import "../../../../styles/utils.scss";

.deletedMutationExpandableRowTop {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: space-between;

    padding-top: 2.4rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    margin-bottom: 2.4rem;

    .row {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        margin-top: 1rem;

        @include tablet {
            order: 1;
        }
    }

    .column {
        max-width: 150px;

        &:first-child {
            max-width: 175px;

            b {
                max-width: 175px;
                word-wrap: break-word;
            }
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
            padding-top: 1.6rem;

            @include tablet {
                padding: 0;
            }
        }

        &:nth-child(3) {
            max-width: 330px;
            @include tablet {
                max-width: unset;
            }
        }

        &:nth-child(4) {
            p {
                display: block;
                max-width: 150px;
            }
        }

        &:not(:nth-child(5)) {
            flex-grow: 1;
            flex-basis: 0;
        }

        &:nth-child(5){
            display: flex;
        }

        .mutationContentRow {
            display: flex;

            &.more {
                margin-top: 1.6rem;
            }

            .mutationContentRowLeft {
                flex-basis: 50%;
                max-width: 165px;

                p:last-child {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                @include tablet {
                    p:first-child {
                        display: none;
                    }
                }
            }
            .mutationContentRowRight {
                display: flex;
                flex-basis: 50%;
                align-items: flex-end;
                margin-left: .5rem;
                max-width: 165px;

                p:last-child {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                @include tablet {
                    display: block;
                    p:first-child(1) {
                        display: none;
                    }
                }
            }
        }

        .expandable {
            max-height: 0;
            overflow: hidden;
            &.expanded {
                max-height: 300px;
            }
        }

        p {
            display: flex;
            align-items: center;
            word-wrap: break-word;
        }

        .mobileHeader {
            display: none;
            color: $grey;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: -0.15px;
            line-height: 1.8rem;
            margin-bottom: 0.4rem;

            @include tablet {
                display: block;
            }
        }


        @include tablet {
            display: unset;
            max-width: unset;
            order: 1;
            flex-basis: 50% !important;
            margin-bottom: 2.4rem;

            &:nth-child(1) {
                order: 0;
                flex-basis: 100% !important;
            }

            &:nth-child(3) {
                order: 0;
                flex-basis: 100% !important;
            }

            &:nth-child(5) {
                position: absolute;
                right: 2.4rem;
            }

            &:nth-child(2),
            &:nth-child(4) {
                margin-bottom: 0;
            }


        }
    }
}
