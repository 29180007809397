@import "../../../../styles/utils.scss";

.insurantDetailsMutationExpandableRowTop {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    padding-top: 2.4rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    
    &:last-child {
        margin-bottom: 2.4rem;
    }

    .column {
        max-width: 150px;


        &:nth-child(2) {
            max-width: 330px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            padding-top: 1.8rem;

            @include tablet {
                padding: 0;
            }
        }

        &:not(:last-child) {
            flex-grow: 1;
            flex-basis: 0;
        }

        &:last-child {
            display: flex;
        }

        .mutationContentRow {
            display: flex;

            &.more {
                margin-top: 1.6rem;
            }

            .mutationContentRowLeft {
                flex-basis: 50%;
                max-width: 165px;

                p:last-child {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                @include tablet {
                    p:first-child {
                        display: none;
                    }
                }
            }
            .mutationContentRowRight {
                display: flex;
                flex-basis: 50%;
                align-items: flex-end;
                margin-left: .5rem;
                max-width: 165px;

                p:last-child {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                @include tablet {
                    display: block;
                }
            }
        }

        .expandable {
            max-height: 0;
            overflow: hidden;
            &.expanded {
                max-height: 300px;
            }
        }

        p {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .mobileHeader {
            display: none;
            color: $grey;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: -0.15px;
            line-height: 1.8rem;
            margin-bottom: 0.4rem;

            @include tablet {
                display: block;
            }
        }

        @include tablet {
            display: unset;
            max-width: unset;

            &:nth-child(1) {
                flex-basis: 100% !important;
            }

            &:nth-child(3) {
                flex-basis: 100% !important;
            }

            &:nth-child(4) {
                position: absolute;
                right: 2.4rem;
            }

            flex-basis: 50% !important;
            margin-bottom: 2.4rem;
        }
    }
}

.insurantColumn {
    display: flex;
    flex-direction: column;
}
