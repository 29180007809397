.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    width: 306px;
    margin: 0 auto;
    text-align: center;
}


@keyframes animateSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }

.spinner-animation {
    animation: animateSpinner 1.5s infinite;
    margin-bottom: 24px;
}