.button {
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    width: auto;
    border-radius: 28px;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 8px 32px;
    transition: all .2s linear;
    position: relative;

    &:after {
        opacity: 0;
        content: "";
        position: absolute;
        top: -7px;
        left: -7px;
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border-radius: 36px;
        border-width: 2px;
        border-style: solid;
        transition: opacity .2s linear;
    }

    &:focus {
        &:after {
            opacity: 1;
            transition: opacity .2s linear;
        }
    }

    .iconWrapper {
        line-height: 36px !important;
        cursor: unset !important;
    }

    &.full-width {
        width: 100%;
    }

    &.primary {
        color: #FFFFFF;
        border-color: var(--main);
        background-color: var(--main);

        &:after {
            border-color: var(--main);
            box-shadow: 0px 0px 0px 6px rgba(38, 50, 56, 0.1);
        }

        &:hover {
            border-color: #455347;
            background-color: #455347;
            transition: all .2s linear;
        }
    }

    &.green {
        color: #FFFFFF;
        border-color: var(--green-light);
        background-color: var(--green-light);

        &:after {
            border-color: var(--green-light);
            box-shadow: 0px 0px 0px 6px rgba(196, 215, 135, 0.1);
        }

        &:hover {
            border-color: #B5C67E;
            background-color: #B5C67E;
            transition: all .2s linear;
        }
    }

    &.red {
        color: #FFFFFF;
        border-color: var(--red-light);
        background-color: var(--red-light);

        &:after {
            border-color: var(--red-light);
            box-shadow: 0px 0px 0px 6px rgba(215, 135, 135, 0.1);
        }

        &:hover {
            border-color: var(--red);
            background-color: var(--red);
            transition: all .2s linear;
        }
    }

    &.white {
        color: var(--green-light);
        border-color: #FFFFFF;
        background-color: #FFFFFF;

        &:after {
            border-color: rgb(255, 255, 255);
            box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
        }

        &:hover {
            //
        }
    }

    &.outline {
        background: none !important;
        position: relative;

        &.primary {
            color: var(--main);
            border-color: var(--main);
            transition: all .2s linear;

            &:after {
                border-color: var(--main);
                box-shadow: 0px 0px 0px 6px rgba(38, 50, 56, 0.1);
            }

            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 2px var(--main);
            }
        }

        &.green {
            color: var(--green-light);
            border-color: var(--green-light);
            transition: all .2s linear;

            &:after {
                border-color: var(--main);
                box-shadow: 0px 0px 0px 6px rgba(38, 50, 56, 0.1);
            }

            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 2px var(--green-light);
            }
        }

        &.red {
            color: var(--red-light);
            border-color: var(--red-light);
            transition: all .2s linear;

            &:after {
                border-color: var(--red-light);
                box-shadow: 0px 0px 0px 6px rgba(215, 135, 135, 0.1);
            }

            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 2px var(--red-light);
            }
        }
    }
}