@import "../../../styles/utils";

.dropdown {
   position: absolute;
   cursor: pointer;
   width: 100%;
   bottom: 0;
}

.hasValue {
   color: $content;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.clear {
   display: block;
   position: absolute;
   right: 30px;
   top: 50%;
   transform: translateY(-50%);

}

.options {
   position: absolute;
   left: 0;
   height: 0;
   top: calc(100% - 0px);
   width: 100%;
   opacity: 0;
   color: $content;
   transition: opacity 200ms;
   overflow-y: auto;
   background: #F3F3F3;
}

.open {
   .options {
      opacity: 1;
      height: auto;
      max-height: 300px;
      z-index: 2;
   }

   .arrow svg {
      transform: rotate(180deg);
   }
}

.arrow {
   position: absolute;
   right: 0;
   top: 50%;
   text-align: right;
   padding: 15px 5px;
   transform: translateY(-50%);
   pointer-events: none;
}
.arrow path{
   stroke: #969899;
}

.dropdown {
   &.disabled {
      .arrow path{
         stroke: $medium-grey;
      }

      .clear path {
         stroke: $medium-grey;
      }
   }
}
