@import "../../../../styles/utils";

.sentExpandableRowContent {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    max-width: 150px;

    &:not(:first-child) {
      margin-top: 1.8rem;
    }

    p {
      display: flex;
      align-items: center;
      word-wrap: break-word;
    }

    &:first-child {
      max-width: 280px;

      b {
        max-width: 280px;
        word-wrap: break-word;

        span {
          margin-left: .2rem;
          white-space: nowrap;
          font-weight: normal;
        }
      }
    }

    &:nth-child(2) {
      p {
        display: block;
      }
    }

    &:nth-child(3) {
      max-width: 100px;
    }

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }

    .mobileHeader {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;
    }
  }
}
