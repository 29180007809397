.main-container.pensionskasse {


    .main-content-container {
        min-height: 100vh;
        //background-color: var(--grey-light)
    }

    .sidebar-container {
        text-align: right;
        padding-left: 48px;
        padding-right: 48px;
    }

}

.sidebarLink {

    &.mutationen {
        svg {
            width: 100%;
        }
    }

    &.active {
        color: var(--main);
        font-weight: bold;
        background-color: var(--grey-light);

        &.cockpit {
            svg {
                circle {

                    &:first-child {
                        display: block !important;
                        stroke: none !important;
                    }

                    &:not(:last-child) {
                        stroke: var(--main);

                    }
                }
                path {
                    fill: var(--main);
                }
            }
        }

        &.mutationen {
            svg {
                path {
                    &:nth-child(1) {
                        fill: var(--main);
                    }
                    &:nth-child(3) {
                        display: block !important;
                    }
                    &:nth-child(4) {
                        fill: var(--main);
                    }
                    &:nth-child(5) {
                        stroke: var(--main);
                    }
                }
            }
        }

        &.versicherte {
            svg {
                path {
                    stroke: var(--main);
                }
                circle {
                    display: block;

                    &:nth-child(2), &:nth-child(3){
                        display: block !important;
                    }

                    &:nth-child(4), &:nth-child(5){
                        stroke: var(--main);
                    }
                }
            }
        }

        &.firmen {
            svg {
                path {
                    stroke: var(--main);

                    &:nth-child(5) {
                        stroke: none;
                        fill: var(--main);
                    }
                }
                polygon {
                    fill: var(--main);
                }
            }
        }

        &.rechnung {
            rect, path {
                stroke: var(--main);
            }
        }
    }

}
