@import "../../../styles/utils";



.cockpit-circles-row {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 26px 116px;
    margin-bottom: 48px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 18px 60px -30px rgba(38,50,56,0.2);

    &.second-row {
        @include tablet {
            margin-bottom: 12.4rem;
        }
    }

    @include tablet {
        padding: 3.2rem;

        &>div {
            flex-basis: 50%;
        }
    }
}

.first-row {
    margin-top: 13rem;

    @include tablet {
        margin-top: 4.8rem;
    }
}

