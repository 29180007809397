@import "../../../../../styles/utils";

.payroll-expandable-row-top {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    display: flex;
    align-items: center;
    max-width: 130px;

    &:not(:last-child) {
      flex: 1 1 0;
    }

    &:not(:first-child),
    &:not(:last-child) {
      padding: 0 0.5rem;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      max-width: 130px;
    }

    .mobile-header {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    @include tablet {
      display: unset;
      max-width: unset;

      &:last-child {
        display: flex;
        margin-bottom: 0;
        justify-content: flex-end;
      }

      flex-basis: 50%;
      margin-bottom: 2.4rem;
    }
  }
}

hr {
  opacity: 0.2;
}

.mt-10 {
  max-width: unset !important;
  margin-top: 1rem;
}

.mb-10 {
  max-width: unset !important;
  margin-bottom: 1rem;
}

.dropdownLabel {
  text-align: center !important;
  max-width: unset !important;
  cursor: pointer;
}

.isRed {
  max-width: unset !important;
  color: $error;
}
