@import "../../../../styles/utils";

.companiesExpandableRowBottom {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    max-width: 120px;

    &:first-child {
     &>p {
        display: flex;
        p:first-child {
          min-width: unset;
          max-width: 4rem;
        }
      }
    }

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }

    p {
      display: block;
      align-items: center;
      word-wrap: break-word;
    }

    .mobileHeader {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    &:nth-child(1) {
      min-width: 275px;
      p {
        min-width: 275px;
      }
    }

    &:nth-child(4) {
      max-width: 200px;
      p {
        display: block;
      }
    }

    @include tablet {
      max-width: unset;
      &:nth-child(1) {
        flex-basis: 100%;
        margin-bottom: 2.4rem;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
}
