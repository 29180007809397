.inputLabel {
   display: block;
   width: 100%;
   border: none;
   color: #969899;
   font-size: 14px;
   font-weight: 400;
   line-height: 17px;
   white-space: normal;
}
