@import "../../../../styles/utils";

.insurantDetailsRowTop {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    flex-grow: 1;
    flex-basis: 0;

    p {
      display: flex;
      align-items: center;
    }

    .headerText {
      color: $grey;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;
      margin-bottom: 1rem;
      display: block;

      @include tablet {
        font-size: 1.2rem;
        margin-bottom: .4rem;
      }
    }

    &:nth-child(4) {
      max-width: 150px;
    }

    &:nth-child(5) {
      max-width: 100px;
    }

    @include tablet {
      max-width: unset;

      &:nth-child(1) {
        flex-basis: 100%;
      }

      &:nth-child(2) {
        flex-basis: 50%;
        margin-top: 2.4rem;
      }

      &:nth-child(3) {
        flex-basis: 50%;
        margin-top: 2.4rem;
      }

      &:nth-child(4) {
        max-width: unset;
        flex-basis: 50%;
        margin-top: 2.4rem;
      }

      &:nth-child(5) {
        max-width: unset;
        flex-basis: 50%;
        margin-top: 2.4rem;
      }
    }
  }
}
