@import '../../styles/config.scss';
@import '../../styles/utils';

.dropdownCollapse {
    display: flex;
    // z-index: 14;
    //z-index: 7;

    @include tablet {
        margin-right: 0;
        max-width: 100vw;
        //height: 60px;
        // pointer-events: none;
    }
}

.collapseButton {
    display: flex;
    white-space: pre-wrap !important;
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap !important;
    white-space: -o-pre-wrap !important;
    word-wrap: break-word !important;
    // background-color: initial !important;
    // edge fix
    background-color: white !important;
    border: none;
    color: var(--main-light);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    padding: 9px 0 8px !important;
    height: 100%;
    align-items: center;

    @include tablet {
        display: none !important;
    }
}

.label {
    margin: auto;
    height: 18px;
}

.labelContainer {
    position: relative;
    // height: 50px;
    height: 100%;
    // width: 125px;
    padding-right: 20px;
    color: var(--main-light);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 16px;
    z-index: 2;
    @include tablet {
        margin-left: auto;
    }
}

.contentContainer {
    position: absolute;
    width: 356px;
    border-radius: 8px;
    background-color: #FFFFFF;
    // box-shadow: 0 10px 30px -20px rgba(1, 50, 62, 0.3);
    box-shadow: -10px 0 30px -20px rgba(1,50,62,0.3);
    top: 54px;
    right: 30px;
    z-index: 99;


    @include tablet {
        right: 0;
        top: 0;
        width: 100%;
        position: fixed;
    }
}

.arrow {
    margin-bottom: 3px;
    margin-left: 10px;
    height: 8px;
    width: 8px;
    border-bottom: 2px solid var(--grey);
    border-right: 2px solid var(--grey);
    transform: rotate(45deg);
    transition: 500ms transform;
}

.activeArrow {
    transform: rotate(-135deg);
}

.content {
    // display: none;
    max-height: 0;
    overflow: hidden;
    transition: 0.6s ease-in-out max-height;

    &.isOpened,
    &:focus-within {
        max-height: 500px;
        // display: block;
    }

    .paddingContainer {
        padding: 12px 32px 32px;
    }

    .smallPaddingContainer {
        padding: 22px 22px 22px;
    }
}
