@import "../../styles/utils";

.sidebarRoot {
  @include tablet {
    position: fixed !important;
    top: auto !important;
    left: 0;
    right: 0;
    bottom: 0;
    height: 72px;
    z-index: 14;
    box-shadow: 0 -9px 40px -20px rgba(36,47,56,0.3);
  }
}

.sidebarContainer {
  position: absolute;
  left: 0;

  display: flex;
  flex-direction: column;

  padding: 0 16px;
  z-index: 12;
  height: 100vh;
  overflow: hidden;
  background-color: white;

  @include tablet {
    left: 0;
    right: 0;
    bottom: 0;
    height: 72px;
    flex-direction: row;

  }

}

.sidebar {
  width: 232px;
  background-color: #FFFFFF;
  box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2) !important;
}


.sidebarOverlay {
  display: none;
}

.sidebarLinks {
  white-space: nowrap;
  //padding-top: 80px;
  flex: 0;
  min-width: 48px;
  margin-top: auto;
  margin-bottom: auto;

  @include tablet {
    display: flex;
    //align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 0;
  }
}

.closed {
  .sidebarLink {
    span {
      display: none;
      //justify-content: space-between;
    };
  }
}

.sidebarLink {
  position: relative;
  display: flex;
  //justify-content: center;
  padding: 0 12px;
  align-items: center;
  height: 48px;
  width: 200px;
  border: none;
  border-radius: 8px;
  background-color: white;
  //background-color: #F3F3F3;

  &:after {
    opacity: 0;
    //content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 10px;
    box-shadow: 0 0 0 2px rgba($action, 1),
    0 0 0 8px rgba($action, 0.1);
    transition: all .2s linear;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  p {
    color: $grey;
    margin-left: 12px;
  }

  &:hover {
    background-color: #F3F3F3;
    p {
      color: $action;
      font-weight: bold;
    }
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:focus {
    background-color: #F3F3F3;
    &:after{
      opacity: 1;
    }
    p {
      color: $action;
      font-weight: bold;
    }
  }
}

.mobileLink {
  display: none;

  @include tablet {
    display: flex;
  }
}

.hamburger {
  margin-top: 22px;
  height: 40px;
  width: 48px;
  outline: none;
  border: none;
  background-color: white;

  &:focus {
    border-radius: 4px;
    //box-shadow: 0px 0px 0px 2px rgba($action, 1),
    //0px 0px 0px 6px rgba($action, 0.1);
    transition: all .2s linear;
  }

  @include tablet {
    display: none;
  }

  * {
    pointer-events: none;
  }
}
