/* Utilities */
.bgMain {
  background-color: var(--main);
}

.colorGreyLight {
  color: var(--grey-light);
}

.colorGrey {
  color: var(--grey);
}

.colorMain {
  color: var(--main);
}

.colorGreen {
  color: var(--green-light);
}

.bgGreen {
    background-color: var(--green-light);
}

.font-16 {
  font-size: 1.6rem;
  letter-spacing: -0.2px;
  line-height: 1.6rem;
}

.font-14 {
  font-size: 1.4rem !important;
  // letter-spacing: -0.2px;
  line-height: 1.4rem !important;
}

.pointer {
  cursor: pointer;
}

.font-500 {
  font-weight: 500;
}

.iconWrapper {
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &.large {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  &.small {
    width: 14px;
    line-height: 14px;
  }

  img, svg {
    max-width: 100%;
    width: auto;
    height: 100%;
  }



  &.filled {
      .toggle {
          transition: fill .2s linear, stroke .2s linear;
      }
      &:hover {
          .toggle {
              fill: var(--green-light);
              stroke: transparent;
          }
      }


  }

  &:hover, &.active {
      .toggle {
          stroke: var(--green-light);
      }
  }
}

.p-relative {
  position: relative;
}

.strokeWhite {
  .toggle {
    stroke: white;
  }
}

.strokeGrey {
  .toggle {
    stroke: var(--grey);
  }
}

.fillWhite {
  .toggle {
    fill: white;
  }
}

.nonButton {
  background-color: transparent;
  border: none;
}

.mt-40 {
  margin-top: 40px;
}

.toggle {
    transition: fill .2s linear;
    transition: stroke .2s linear;
}
