@import "../../../../styles/utils";

.insurantDetailsRowBottom {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 2.4rem 3.2rem;

  .column {
    flex-grow: 1;
    flex-basis: 0;

    .headerText {
      color: $grey;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;

      @include tablet {
        font-size: 1.2rem;
        margin-bottom: .4rem;
      }
    }

    p {
      margin-top: 1rem;

      @include tablet {
        margin-top: 0;
      }
    }

    &:nth-child(5),
    &:nth-child(11),
    &:nth-child(17){
      max-width: 100px;
    }

    &:nth-child(4),
    &:nth-child(10),
    &:nth-child(16){
      max-width: 150px;
    }

    @include tablet {
      flex-basis: 50%;
      max-width: unset !important;

      margin-bottom: 2.4rem;

      //&:nth-child(8),
      //&:nth-child(9) {
      //  margin-bottom: 0;
      //}
    }
  }

  .break {
    flex-basis: 100%;
    margin-top: 3.2rem;

    @include tablet {
      margin: 0;
    }
  }
}
