@import "../../../../styles/utils";

.listView {
  margin-top: 6.4rem;

  @include tablet {
    margin-top: 4rem;
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  margin-bottom: 2.4rem;

  @include tablet {
    display: none;
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 150px;

    color: $grey;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;

    &:first-child {
      max-width: 280px;
    }

    &:nth-child(3) {
      max-width: 100px;
    }

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}

.listItem {
  &>div {
    &>div:last-child {
      margin-top: 1rem;
    }
  }
}

