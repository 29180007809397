@import "../../../../styles/utils";

.insurantDetailsRowTop {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    flex-grow: 1;
    flex-basis: 0;

    p {
      display: flex;
      align-items: center;
    }

    .headerText {
      color: $grey;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;
      margin-bottom: 1rem;


        display: block;
    }

    @include tablet {

      &:nth-child(1) {
        flex-basis: 100%;
      }

      &:nth-child(2) {
        flex-basis: 50%;
        margin-top: 2.4rem;
      }

      &:nth-child(3) {
        flex-basis: 50%;
        margin-top: 2.4rem;
      }

      &:nth-child(4) {
        flex-basis: 50%;
        margin-top: 2.4rem;
      }
    }
  }
}
