@import '../../../../styles/utils.scss';

.success-message-wrap {
    display: flex;
    justify-content: center;
}

.success-message-space {
    width: 45rem;
    text-align: center;
    font-weight: 500;
    color: var(--main);
    margin-bottom: 3.2rem;

    @include phone {
        width: auto;
    }
}