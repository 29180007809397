@import "../../styles/utils";

.stickyActions {
   display: none;
   position: fixed;
   width: 100%;
   z-index: 13;
   list-style: none;
   margin-bottom: 72px;
   padding: 20px;
   background-color: white;
   box-shadow: 0 8px 30px 0 rgba(1,50,62,0.2);
   transition: all .3s ease-in;

   &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      height: 4px;
      width: 40px;
      border-radius: 2px;
      background-color: $light-grey;
   }

   &.expanded {
      z-index: 14;
   }

   @include tablet {
      display: block;
   }
}

.stickyActionItem {
   display: flex;
   align-items: center;

   height: 72px;
   border-bottom: 1px solid $lighter-grey;
}

.stickyActionButton {
   position: absolute;
   right: 20px;
   //top: calc(-56px - 20px - 72px);
   height: 56px;
   width: 56px;
   outline: none;
   border: none;
   border-radius: 28px;
   background-color: $action;
   box-shadow: 0 6px 20px 0 rgba(36,47,56,0.5)
}
