.invoices {
    &.contentContainer {
        //   padding-top: 0;

        .customContainer {
            min-height: calc(100vh - 245px);
        }
        //
        & > .container-fluid {
            //     // margin-top: 130px;
            //     padding: 0 47px;
            padding-top: 120px;
            //
            //     @media (max-width: 767px) {
            //         padding: 0 15px !important;
            //     }
            //
            //     @media (max-width: 991px) {
            //         padding: 0 25px !important;
            //     }
            //
        }
    }
}


.filterIcon {
    @media (min-width: 1400px) {
        visibility: hidden;
    }
}

.breaker {
    height: 1px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 rgba(175,175,177,0.16);
    margin-top: 50px;
}

.paddingContainer {
    padding: 0 48px;

        text-align: right;

    p {
        text-align: left;
    }

}

.contentContainer {
    padding-top: 0;

    & > .container-fluid {
        // margin-top: 130px;
        padding: 0;
        @media (max-width: 767px) {
            padding: 40px 0 !important;

        }
    }
}

.expandable-row-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #263238
}

.rechnungen-modal-back-icon g{
    stroke: #AFAFB1;
    transition: 0.1s stroke;
}
.rechnungen-modal-back-icon-wrapper:hover g{
    stroke: black;
}
.rechnungen-modal-back-icon-wrapper{
    cursor: pointer;
}