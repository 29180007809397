@import "../../../../styles/utils";

.mutationExpandableRowErrorBottom {
  padding: 1.2rem 3.2rem 3.2rem 3.2rem;
}

.tabs {
  display: flex;
  margin-bottom: 3.6rem;
  border-bottom: 1px solid $light-grey;
}

.textContent {
  height: 12rem;

  @include larger-tablet {
    height: 14rem;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 6rem;

  @include tablet {
    height: unset;
    flex-direction: column;

    .radioInputs {
      margin-bottom: 3.2rem;
    }
  }
}
