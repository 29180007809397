@import '../../../styles/utils';

.checkbox {
   width: 1px;
   height: 1px;
   display: none;
}

.label {
   display: flex;
   align-items: flex-start;
   position: relative;
   padding: 0;
   cursor: pointer;
   width: auto;
   max-width: 100%;
   white-space: pre-wrap;
   height: auto;
   max-height: 100%;
   color: $content;
   font-size: 14px;
   letter-spacing: -0.17px;
   line-height: 1.6rem;
   outline: 0;
   transition: all .2s linear;

   &:before {
      content: "";
      opacity: 0;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 2px;
      border: 2px solid $action;
      box-shadow: 0 0 0 4px rgba($action, 0.1);
   }

   &:focus, &:hover {
      &:before {
         //opacity: 1;
         border: 2px solid $action;
      }

      .checkmark {
         & g[stroke="#AFAFB1"] {
            stroke: $action;
         }
      }
   }

   &.checked {
      .checkmark {
         & g[stroke="#AFAFB1"] {
            stroke: $highlight;
         }
      }

      &:before {
         border: 2px solid $highlight;
      }
   }
}

.checkmark {
   height: 18px;
   min-height: 18px;
   width: 18px;
   min-width: 18px;
   margin-top: -1px;

   &.hasLabel{
      margin-right: 10px;
   }
}
