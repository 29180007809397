@import "../../../../../styles/utils";

.unpaid-vacation-expandable-row-top {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    display: flex;
    align-items: center;

    &:nth-child(1),
    &:nth-child(2) {
      max-width: 120px;
    }


    &:not(:last-child) {
      flex: 1 1 0;
    }

    &:not(:first-child),
    &:not(:last-child) {
      padding: 0 .5rem;
    }

    

    .mobile-header {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    @include tablet {
      display: unset;
      max-width: unset;

      &:last-child {
        display: flex;
        margin-bottom: 0;
        justify-content: flex-end;
        flex-basis: 100%;
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        flex-basis: 100% !important;
      }

      flex-basis: 50%;
      margin-bottom: 2.4rem;
    }
  }
}
