@import '../../../styles/utils.scss';

.radioWrapper {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    padding: 12px 0 0;

    &:after {
        content: "";
        opacity: 0;
        position: absolute;
        top: -10px;
        left: -12px;
        width: calc(100% + 20px);
        height: calc(100% + 16px);
        border: 2px solid black;
        border-radius: 4px;
        box-shadow: 0px 0px 0px 4px rgba(38, 50, 56, 0.1);
        pointer-events: none;
    }

   &.disabled {
      opacity: .6;
      pointer-events: none;
   }
}

.focused {
    &:after {
        //opacity: 1;
    }
}

.radioLabel {
    font-size: 13px;
    background-color: $light-grey;
}

// .radioInput:checked ~ .radioInputMark {
//     /*left: 7px;
//     top: 7px;
//     width: 10px;
//     height: 10px;*/
//     background: url("../../../assets/checkmarkSlim.svg") center / contain no-repeat;
// }

.radioInput:checked ~ .radioLabel:before {
    padding: 0;
    background-color: #1B8DB3;
    //border: 1px solid $content;
}

input.radioInput {
    width: 100%;
    // max-width: 115px;
    left: 0;
    height: 100%;
    z-index: 10;
}

input.radioInput {

    &:checked  {
        & ~ .radioLabel:not(.input-wrapper) {
            padding: 0;
            //border: 1px solid transparent;
            box-shadow: inset 0 -2px 0 0 #1B8DB3;
            font-weight: 500;
        }
    }
    &:hover  {
        & ~ .radioLabel:not(.input-wrapper) {
            color: #10446A;
            font-weight: 600;
        }

    }
}


input.radioInput ~ .radioLabel {
    padding: 0;
    text-align: center;
    //border: 1px solid transparent;
    transition: box-shadow linear 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}
// .radioInputMark {
//     left: 7px;
//     top: 7px;
//     width: 10px;
//     height: 10px;
// }

.radio {
    height: 22px;
    flex: 1 1 100px;
    margin-right: 5px;
    margin-bottom: 4px;

    &:first-child {
        margin-left: 0;
    }
}

.radioLabel {
    background-color: transparent;
    //border: 1px solid transparent;
    width: 100%;
    // max-width: 115px;
    height: 100%;
    color: $content;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 16px;
    text-align: center;
    line-height: 46px;
    // padding-left: 30px !important;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     margin: auto 0;
    //     bottom: 0;
    //     display: block;
    //     width: 22px;
    //     height: 22px;
    //     border-radius: 50%;
    //     border: 1px solid gray;
    // }
}

.radioWrapper {
    padding: 0;
    text-align: center;
    margin-bottom: -4px;
}

.radio {
    position: relative;
    display: inline-block;
    vertical-align: top;
    // height: 90px;
    height: 48px;
    // width: 101.5px;
}

.radioLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1px;
    background-color: #F5F5F5;
    cursor: pointer;
}

input.radioInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
// .radioInputMark {
// position: absolute;
// bottom: 11px;
// right: 11px;
// height: 25px;
// width: 25px;
// z-index: 1;
// }
// input.radioInput:checked ~ .radioInputMark {
//     background: url("../../../assets/icons/checkmark-slim.svg") center / contain no-repeat;
// }

input.radioInput:checked ~ .radioLabel {
    padding: 0;
    // border: 1px solid $content;
}
