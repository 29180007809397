@import "../../../../styles/utils";

.listView {
  margin-top: 12.8rem;

  @include tablet {
    margin-top: 4rem;
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  margin-bottom: 2.4rem;

  @include tablet {
    display: none;
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 120px;

    color: $grey;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }

    &:nth-child(1) {
      min-width: 300px;
    }


    &:nth-child(4) {
      max-width: 200px;
    }
  }
}
