@import "../../styles/utils";

// .PdfViewerContainer {

// }

// .PdfViewerTitle {

// }

// .PdfViewerContent {

// }

// #pdfContainer {
//     margin-bottom: 40px;
//     z-index: 99999;

//     width: 100%;
//     height: calc(100vh - 200px);
// }
.pdfViewer {
    margin-top: 9.4rem;
    //height: calc(100vh - 18rem);
  }
  
  .pdfViewerContainer {
    overflow: visible;
    height: calc(100vh - 10rem);
    //min-height: 100vh;
    margin-top: 9.4rem;
    padding-bottom: 7rem;
  
    @include tablet {
      height: calc(100vh - 30rem);
    }
    //height: calc(100vh - 18rem);
  }