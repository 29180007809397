@import "../../styles/utils";

.inputWrapper {
   position: relative;
   display: flex;
   width: auto;

   height: 73px;
   min-height: 73px;
   max-height: 73px;

   flex-wrap: wrap;
   vertical-align: top;
   text-align: left;

   margin-top: 16px;
   margin-bottom: 26px;

   ::-ms-clear {
      display: none;
   }

   label {
      margin-bottom: 0;
   }
}

.checkboxWrapper, .radioWrapper {
   height: auto;
   min-height: unset;
   max-height: unset;

   & > div {
      top: 100% !important
   }
}

.error {
   input {
      color: $error !important;
      border-color: $error;

      &:hover, &:focus {
         border-color: $error;
      }
   }

   &.checkboxWrapper {
      svg {
         & g[stroke="#AFAFB1"],
         & g[stroke="#1B8DB3"],
         & g[stroke="#145685"] {
            stroke: $error !important;
         }
      }
   }
}

.warning {
   input {
      color: $warning !important;
      border-color: $warning;

      &:hover, &:focus {
         border-color: $warning;
      }
   }
}
