@import "../../../../styles/utils";

.wrapper {
  background-color: transparent;
}

.form-wrapper {
  background-color: #ffff;
  padding: 48px;
  border-radius: 8px;
  margin-bottom: 52px;
  margin-top: 48px;

  @include tablet {
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 48px;
  }
}

.salary-title {
  font-size: 22px;
  color: #242f38;

  @include tablet {
    margin-left: 21px;
  }
}

.course-title {
  margin-bottom: 38px;
  font-size: 20px;
  color: #242f38;

  @include tablet {
    margin-left: 21px;
    margin-bottom: 41px;
  }
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  margin-bottom: 24px;

  @include tablet {
    display: none;
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 150px;

    color: $grey;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;

    white-space: nowrap;

    &:not(:first-child),
    &:not(:last-child) {
      padding: 0 0.5rem;
    }

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
