@import "../../../../styles/utils";

.personenExpandableRowBottom {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 2.4rem 3.2rem;

  .column {
    max-width: 150px;


      flex-grow: 1;
      flex-basis: 0;


    &:nth-child(5),
    &:nth-child(11) {
      flex-grow: unset;
      flex-basis: unset;
    }

    .headerText {
      color: $grey;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;
    }

    p {
      margin-top: 1rem;
    }

    @include tablet {
      flex-basis: 50%;
      max-width: unset;
      margin-bottom: 2.4rem;

      &:nth-child(10),
      &:nth-child(11) {
        margin-bottom: 0
      }
    }
  }

  .break {
    flex-basis: 100%;
    margin-top: 3.2rem;

    @include tablet {
      margin: 0;
    }
  }
}
