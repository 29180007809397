@import "../../../../styles/utils";

.option {
   position: relative;
   color: $content;
   padding: 10px 16px;
   font-size: 14px;
   line-height: 16px;
   font-weight: 500;
   word-break: break-word;
   letter-spacing: 0.2px;
   border-bottom: 1px solid $light-grey;

   &:hover, &.active {
      background-color: #D0DDE7;
      color: #10446A;
      font-weight: bolder;
   }
}
