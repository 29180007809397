@import 'styles/utils';

// override toastr
.redux-toastr .toastr .rrt-middle-container {
    width: 90% !important;
    padding: 0px 2px !important;
    margin-left: unset !important;
 }

 .redux-toastr .toastr .close-toastr { 
    color: white !important;
    font-size: 30px !important;
    right: 10px !important;
 }

#root {
    height: 100vh;
    overflow: hidden;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.relative-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.main-container {
    position: relative;
    height: 100%;
}

.main-content-container {
    display: flex;
    height: 100%;
    padding-left: 80px;

    @include tablet {
        padding-left: 0;
    }
}

#scrollableContainerParent {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.content-container {
    position: relative;
    padding: 0 3.2rem;
    overflow: auto;
    height: calc(100vh - var(--header-height));
    width: 100%;
    min-height: calc(100vh - var(--header-height));

    &.max-width-container {
        max-height: calc(100vh - var(--header-height));
    }

    @include tablet {
        padding: 3.2rem 0 10rem 0;
        overflow-x: hidden;
    }
}

.add-new-button {
    position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    outline: 0;
    transition: all .2s linear;
    cursor: pointer;

    &:after {
        opacity: 0;
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        border: 2px solid #455347;
        border-radius: 4px;
        box-shadow: 0px 0px 0px 7px rgba(69, 83, 71, 0.1);
        transition: all .2s linear;
    }

    &:not(.disabled) {
        &:hover{
            .addButton {
                background-color: #455347;
                border-color: #455347;
                transition: all .2s linear;
            }
            span {
                color: #455347;
                transition: all .2s linear;
            }
        }

        &:focus {
            .addButton {
                background-color: #455347;
                border-color: #455347;
                transition: all .2s linear;
            }
            span {
                color: #455347;
                transition: all .2s linear;
            }
            &:after {
                opacity: 1;
                transition: all .2s linear;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
        .addButton {
            border-color: #E8E9E9;
            background-color: #E8E9E9;
            box-shadow: none;
        }
        span {
            color: var(--grey);
        }
        .iconWrapper {
            cursor: not-allowed;

            svg path {
                stroke: var(--grey);
            }
        }
    }
}

.modal-add-new-button {
    display: inline-flex;
    position: relative;
    outline: 0;
    white-space: nowrap;
    align-items: center;
    flex-wrap: nowrap;

    &:after {
        opacity: 0;
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        border: 2px solid #455347;
        border-radius: 4px;
        box-shadow: 0px 0px 0px 7px rgba(69, 83, 71, 0.1);
        transition: all .2s linear;
    }

    &:hover {
        cursor: pointer;
        .addButton {
            background-color: #455347;
            border-color: #455347;
            transition: all .2s linear;
        }
        span {
            color: #455347;
            transition: all .2s linear;
        }
    }

    &:focus {
        .addButton {
            background-color: #455347;
            border-color: #455347;
            transition: all .2s linear;
        }
        span {
            color: #455347;
            transition: all .2s linear;
        }
        &:after {
            opacity: 1;
            transition: all .2s linear;
        }
    }
}

.overflowHiddenOff {
    overflow: initial !important;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sidebarOverlay {
}

.sidebarContent {
    @media (max-width: 575px) {
    }
}

.sidebar {
    background-color: #fff;
    // left: 80px !important;
    width: 232px;
    background-color: #FFFFFF;
    box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2) !important;
    // & > div {
    //   position: relative;
    //   // left: 80px;
    //   left: 100%;
    //   background-color: white;
    //   background-color: wheat;
    //   height: 100vh;
    //   width: 80px;
    //   padding-top: 56px;
    // }
    &:not(p) {
        text-align: right;
    }

}

// iphone fix
.sidebarContainer {
    // height: 100vh;
    @media (max-width: 575px) {
        position: fixed !important;
        height: 72px;
        z-index: 4;
        top: initial !important;
        box-shadow: 0 -9px 40px -20px rgba(1,50,62,0.3);
    }
}

.sidebarContent {
    // z-index: 1;

    @media (max-width: 575px) {
        // height: 72px;
    }
}

.hamburgerContainer {
    background-color: white;
    left: 0;
    position: absolute;
    // width: 80px;
    padding: 0 16px;
    z-index: 5;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2) !important;


    @include tablet {
        flex-direction: row;
        position: absolute;
        left: 0;
        right: 0;
        height: 72px;
        bottom: 0;

        .links, .support, .profile {
            padding: 13px 0px;
            display: flex;
            flex: 1 1 210px;
            margin-top: auto;
            margin-bottom: auto;
            justify-content: space-between;
        }

        .hamburger {
            display: none;
        }

        .support {
            flex: 1 1 70px;
        }
    }
}

.hamburger {
    background-color: transparent;
    border: none;
    // height: 40px;
    // width: 192px;
    // border: 2px solid var(--main);
    // border-radius: 20px;
    // background-color: white;
    // transition: all 0.3s ease-out 0s;
    // margin: auto;
    // left: 20px;
    // right: 0;
    // top: 20px;
    // color: var(--main);
    // font-size: 16px;
    // font-weight: 600;
    // line-height: 20px;
    // text-align: center;
    transition: all .2s linear;



    &:focus {
        border-radius: 4px;
        box-shadow: 0px 0px 0px 2px rgba($action, 1),
                    0px 0px 0px 6px rgba($action, 0.1);
        transition: all .2s linear;
    }
}

.links {
    white-space: nowrap;
    padding-top: 100px;
    flex: 0;
    min-width: 48px;
    margin-top: auto;
    margin-bottom: auto;
}

.sidebarLink {
    display: block;
    height: 48px;
    line-height: 48px;
    width: 200px;
    border-radius: 8px;
    color: var(--main);
    font-size: 16px;
    letter-spacing: -0.2px;
    transition: all 0.3s ease-out 0s;
    padding: 0 12px;
    // text-align: left;
    white-space: nowrap;
    position: relative;



    &:hover, &:focus {
        background-color: var(--grey-light);
         svg .toggle {
               //stroke: var(--green-light) !important;
           }
    }

    &:focus {
        &:after{
            opacity: 1;
            transition: all .2s linear;
        }
    }
}

.closed {
    .sidebarLink {
        span {
            display: none;
            white-space: nowrap;
        }
    }
}



body {

    &.closed {
        .hamburger {
            // color: #fff;
            // height: 40px;
            // width: 40px;
            // border: 2px solid var(--main);
            // background-color: var(--main);
        }

        .sidebarLink {
            width: 48px;
            max-width: 48px;
            padding-left: 12px;
            padding-right: 12px;
            // text-align: center;
        }
    }

    &.opened {
        .hamburger {}
    }
}

a {
    outline: none !important;
    .toggle {
        transition: fill .2s linear;
        transition: stroke .2s linear;
    }

    p {
        transition: color .2s linear;
    }

    &.filled {
        &:hover {
            .toggle {
                transition: fill .2s linear;
                transition: stroke .2s linear;
                //fill: var(--green-light);
            }
        }
    }

    &:hover {
        text-decoration: none !important;

        p {
            color: var(--green-light);
        }

        .iconWrapper.filled {
            .toggle {
                transition: fill .2s linear;
                transition: stroke .2s linear;
                //fill: var(--green-light);
                stroke: transparent;
            }
        }
    }
}

.iconWrapper:not(.sidebarIconWrapper) {
    &:hover {
        circle.toggle {
            stroke: white !important;
        }
    }
}


.basicLink:hover {
    svg .toggle {
        stroke: var(--grey-dark) !important;
    }
}

fieldset:disabled {
    .inputWrapper, button, p {
        opacity: 0.5;
    }
}

.sidebarIconWrapper svg {
    margin-bottom: 2px;
}

button.pdf-icon svg {
    margin-top: 4px;
}

.sidebar-pdf-link {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    position: relative;
    outline: 0;

    &:after {
        opacity: 0;
        content: "";
        position: absolute;
        top: 0;
        left: -6px;
        width: calc(100% + 12px);
        height: 100%;
        border-radius: 4px;
        border: 2px solid var(--main);
        box-shadow: 0px 0px 0px 6px rgba(38, 50, 56, 0.1);
        transition: all .2s linear;
    }

    &:focus {
        &:after {
            opacity: 1;
        }
    }
}

fieldset:disabled {
    .input-wrapper {
        opacity: .3;

        input:hover, label:hover, svg:hover {
            cursor: not-allowed;

        }
    }
}

.validationMessage.error ~ div {
   input, input::placeholder {
       color: var(--red);
       border-color: var(--red);
   }

    rect, path {
        stroke: var(--red);
    }
}

.help-icon {
    display: inline-block;
    margin-left: 8px;

    svg {
        height: 16px;
        width: 16px;
    }
}

.rc-tooltip {
    opacity: 1;
    margin-top: -20px;
    border-radius: 8px;
    background-color: #263238;
    padding: 8px;
    max-width: 350px;
    box-shadow: 0 27px 100px -20px rgba(38,50,56,0.2),
    0 4px 15px -10px rgba(38,50,56,0.2),
    0 9px 30px -15px rgba(38,50,56,0.2),
    0 18px 60px -30px rgba(38,50,56,0.2);
}

.rc-tooltip-inner {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.17px;
    line-height: 18px;
    background: transparent;
    border: 0;
}

.rc-tooltip-arrow, .rc-tooltip-arrow-inner {
    border-top-color: #263238 !important;
}


