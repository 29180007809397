.checkbox-input.unpaidvacation-checkbox {
  max-height: unset;
  height: auto;
  min-height: unset;

  &.absolute-icon {
    span {
      padding-top: 2px;
    }
    .help-icon {
      position: absolute;
      right: -20px;
    }
  }

  & > div {
    top: 24px;
  }

  label {
    height: auto;
  }
}

.unpaidvacation-checkbox {
  .z-index-high {
    z-index: 999;
  }
  .help-icon {
    z-index: 999;
  }
}

.rc-tooltip {
  opacity: 1 !important;
}

.rc-tooltip-inner {
  color: white !important;
  background-color: #242f38 !important;
  opacity: 1 !important;
}
