@import "../../styles/utils";

.checkmark {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 1.25rem;
  background-color: $verification;
  animation-name: appear-circle;
  animation-duration: .8s;

  svg {
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);
    height: 1.2rem;
    width: 1.2rem;

    animation-name: appear-checkmark;
    animation-duration: .9s;
  }
}

@keyframes appear-circle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes appear-checkmark {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(.5);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.15);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
