$content: #242f38;
$action: #145685;
$highlight: #1b8db3;
$distinct: #77b324;
$grey: #969899;
$disabled-grey: #c4c6c7;
$medium-grey: #d7d8d9;
$light-grey: #e3e5e6;
$lighter-grey: #eeeff0;

$error: #cc3f3d;
$warning: #e67417;
$verification: #138057;

$action-darker: #10446a;
$error-darker: #991533;

$blue-gradient-start: #6bb5ce;
$blue-gradient-end: #b0d7e5;

$light-gray-gradient-start: #d2d4d6;
$light-gray-gradient-end: #e1e4e6;

@mixin heading($font-size, $line-height) {
  margin-bottom: 0;
  font-size: $font-size;
  line-height: $line-height;
}

.mb-12 {
  margin-bottom: 1.2rem;
}
.mb-16 {
  margin-bottom: 1.6rem;
}
.mb-24 {
  margin-bottom: 2.4rem;
}
.mb-26 {
  margin-bottom: 2.6rem;
}
.mb-28 {
  margin-bottom: 2.8rem;
}
.mb-32 {
  margin-bottom: 3.2rem;
}
.mb-40 {
  margin-bottom: 4rem;
}
.mb-48 {
  margin-bottom: 4.8rem;
}
.mb-58 {
  margin-bottom: 5.8rem;
}
.mb-94 {
  margin-bottom: 9.4rem;
}

.mt-16 {
  margin-top: 1.6rem;
}
.mt-22 {
  margin-top: 2.2rem;
}
.mt-24 {
  margin-top: 2.4rem;
}
.mt-26 {
  margin-top: 2.6rem;
}
.mt-28 {
  margin-top: 2.8rem;
}
.mt-32 {
  margin-top: 3.4rem;
}
.mt-40 {
  margin-top: 4rem;
}
.mt-48 {
  margin-top: 4.8rem;
}
.mt-58 {
  margin-top: 5.8rem;
}
.mt-64 {
  margin-top: 6.4rem;
}
.mt-72 {
  margin-top: 7.2rem;
}
.mt-80 {
  margin-top: 8rem;
}
.mt-94 {
  margin-top: 9.4rem;
}
.mt-110 {
  margin-top: 11rem;
}

.ml-10 {
  margin-left: 1rem;
}
.ml-16 {
  margin-left: 1.6rem;
}
.ml-24 {
  margin-left: 2.4rem;
}
.ml-26 {
  margin-left: 2.6rem;
}
.ml-28 {
  margin-left: 2.8rem;
}
.ml-32 {
  margin-left: 3.4rem;
}
.ml-40 {
  margin-left: 4rem;
}
.ml-48 {
  margin-left: 4.8rem;
}
.ml-auto {
  margin-left: auto;
}

.mr-6 {
  margin-right: 0.6rem;
}
.mr-12 {
  margin-right: 1.2rem;
}
.mr-16 {
  margin-right: 1.6rem;
}
.mr-24 {
  margin-right: 2.4rem;
}
.mr-26 {
  margin-right: 2.6rem;
}
.mr-28 {
  margin-right: 2.8rem;
}
.mr-32 {
  margin-right: 3.4rem;
}
.mr-40 {
  margin-right: 4rem;
}
.mr-48 {
  margin-right: 4.8rem;
}
.mr-60 {
  margin-right: 6rem;
}

.pl-16 {
  padding-left: 1.6rem;
}
.pl-24 {
  padding-left: 2.4rem;
}
.pl-32 {
  padding-left: 3.4rem;
}
.pl-40 {
  padding-left: 4rem;
}
.pl-48 {
  padding-left: 4.8rem;
}

.pr-16 {
  padding-right: 1.6rem;
}
.pr-24 {
  padding-right: 2.4rem;
}
.pr-32 {
  padding-right: 3.4rem;
}
.pr-40 {
  padding-right: 4rem;
}
.pr-48 {
  padding-right: 4.8rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}
.pb-24 {
  padding-bottom: 2.4rem;
}
.pb-32 {
  padding-bottom: 3.4rem;
}
.pb-40 {
  padding-bottom: 4rem;
}
.pb-48 {
  padding-bottom: 4.8rem;
}

.pt-16 {
  padding-top: 1.6rem;
}
.pt-24 {
  padding-top: 2.4rem;
}
.pt-32 {
  padding-top: 3.4rem;
}
.pt-40 {
  padding-top: 4rem;
}
.pt-48 {
  padding-top: 4.8rem;
}

.p-16 {
  padding: 1.6rem;
}
.p-24 {
  padding: 2.4rem;
}
.p-32 {
  padding: 3.4rem;
}
.p-40 {
  padding: 4rem;
}
.p-48 {
  padding: 4.8rem;
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}

.text-grey {
  color: $grey;
}

.text-disabled {
  color: $medium-grey;
}

.mb-32-tablet {
  @media (max-width: 768px) {
    margin-bottom: 3.2rem;
  }
}

@mixin smaller-phone {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 822px) {
    @content;
  }
}

@mixin larger-tablet {
  @media (max-width: 1000px) {
    @content;
  }
}

$content: #242f38;
$action: #145685;
$highlight: #1b8db3;
$light-highlight: #75b8cf;
$distinct: #77b324;
$grey: #969899;
$disabled-grey: #c4c6c7;
$medium-grey: #d7d8d9;
$light-grey: #e3e5e6;
$lighter-grey: #eeeff0;

$green: #c4d787;
$red-light: #d78787;

$error: #dd1f4a;
$warning: #e67417;
$verification: #138057;

$action-darker: #10446a;
$error-darker: #991533;

@mixin heading($font-size, $line-height) {
  margin: 0;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin button-primary($color, $darker) {
  background-color: $color;
  border-color: $color;

  &:hover,
  &:focus {
    background-color: $darker;
    border-color: $darker;
    transition: all 0.2s linear;
  }

  &:after {
    border-color: $darker;
    box-shadow: 0 0 0 6px rgba($darker, 0.1);
  }
}

@mixin button-secondary($color, $darker) {
  color: $color;
  border-color: $color;

  &:hover,
  &:focus {
    color: $darker;
    border-color: $darker;
    transition: all 0.2s linear;
  }

  &:after {
    color: $darker;
    border-color: $darker;
    box-shadow: 0 0 0 6px rgba($darker, 0.1);
  }
}

@mixin button-text($color, $darker) {
  color: $color;

  &:hover,
  &:focus {
    color: $darker;
  }

  &:after {
    border-color: $darker;
    box-shadow: 0 0 0 4px rgba($darker, 0.1);
  }
}

@mixin smaller-phone {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
