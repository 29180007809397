@import "../../styles/utils";

.detailsPopupContainer {
  position: relative;

  button {
    z-index: 1;

    svg {
      margin-left: .4rem;
      transform: scale(.7);
    }
  }
}

.detailsPopupContent {
  position: absolute;
  top: -16px;
  left: -18px;
  padding: 6.2rem 1.8rem 2.4rem 1.8rem;

  background-color: white;
  display: none;
  white-space: nowrap;

  min-width: 134px;
  border-radius: 8px;
  box-shadow: 0 27px 80px -20px rgba(38,50,56,0.2),
              0 4px 7.5px -10px rgba(38,50,56,0.2),
              0 9px 15px -15px rgba(38,50,56,0.2), 0 18px 40px -30px rgba(38,50,56,0.2);

    &:after {
        content: "";
        position: absolute;
        top: 47px;
        left: 0;
        height: 1px;
        width: 100%;

        background-color: #FFFFFF;
        box-shadow: inset 0 -1px 0 0 rgba(175,175,177,0.16);
    }
}

.detailsPopupContainer {
  &.isOpen {

    button {
      z-index: 16;

      svg {
        transform: scale(.7) rotateX(180deg);
      }
    }

    .detailsPopupContent {
      display: block;

      z-index: 15;

    }
  }
}

.arrow {
  //margin-bottom: 3px;
  margin-left: 10px;
  height: 8px;
  width: 8px;
  border-bottom: 2px solid $action;
  border-right: 2px solid $action;
  transform: rotate(45deg);
  transition: 500ms transform;
}

.activeArrow {
  transform: rotate(-135deg);
}
