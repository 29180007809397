.waring-icon {
    transform: scale(1.5);
}

.row {
    display: flex;
}

.ml-4 {
    margin-left: .4rem;
}