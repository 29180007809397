// @import '@material/react-tab/index.scss';
// @import '@material/react-tab-indicator/index.scss';
@import '../../node_modules/@material/react-tab/dist/tab.css';
@import '../../node_modules/@material/react-tab-indicator/dist/tab-indicator.css';
@import './bootstrap-reboot.css';
@import './typography.scss';
@import './config.scss';
@import './bootstrap.css';
@import './utilities.scss';
@import './pensionskasse.scss';
@import './utils';

html {
  font-size: 10px;
}

p {
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
}

button {
  outline: none !important;
}

body.closed {
  .sidebar-link {
    width: 48px;
    p {
      display: none;
    }
  }
}

.modal-buttons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-left: auto;
    margin-right: 3.2rem;
  }

  @include tablet {
    flex-direction: column;

    button:first-child {
      order: 1;
      margin: auto;
      margin-top: 3.2rem;
    }
  }


}

.text-small {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.8rem;
}

.text-medium {
  font-size: 1.4rem;
  letter-spacing: -0.17px;
}

.drawer {
  z-index: 13;
}

.main-content-container-left {

  &.pensionskasse {
    padding: 0 3.2rem;
    width: 100%;
    display: block;
    overflow: scroll;
    position: relative;
    height: calc(100vh - 80px);


    @include tablet {
      padding: 0;
    }
  }
}

.expandable-row-content {
  display: flex;
  justify-content: space-between;
  padding-left: 3.2rem;
  padding-right: 3.2rem;

  .column {
    display: flex;
    align-items: center;
    max-width: 150px;

    @include tablet {
      display: unset;
      align-items: unset;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
      &:not(:last-child) {
        flex-grow: 1;
        flex-basis: 0;
      }
  }

  .mobile-header {
    display: none;
    font-size: 1.2rem;
    color: $grey;
    font-weight: 500;
    letter-spacing: -.015rem;
    line-height: 1.8rem;

    @include tablet {
      display: block;
    }
  }
}

.expandable-row-buttons {
  display: flex;
  width: 6.3rem;
  //width: 15rem;
  //margin-left: 2.4rem;
  button {
  }
}

.trash-icon {
  height: 2.4rem;

  svg {
    width: 18px;
    height: 20px;
  }
}

.download-icon {
  height: 2.4rem;

  svg {
    width: 24px;
    height: 24px;
  }
}

.edit-icon {
  height: 2.2rem;

  svg {
    width: 18px;
    height: 18px;
  }
}

.contact-icon {
  height: 2.4rem;

  svg {
    width: 24px;
    height: 24px;
  }
}

.list-header {
  display: flex;
  justify-content: space-between;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  color: $grey;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;

  &.no-action-buttons {
    margin-top: calc(6.4rem + 6.2rem);
  }

  .column {
    max-width: 150px;

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  @include tablet {
    display: none;
  }
}

.expandable-row-bottom {
  display: flex;
  flex-wrap: wrap;
  padding: 2.4rem 3.2rem 0 3.2rem;

  .block {
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 3.2rem;

    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @media (min-width: 768px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .text-grey {
      font-size: 1.4rem;
      margin-bottom: .8rem;
    }
  }
}

.top-action-buttons {
  @include tablet {
    display: none !important;
  }
}

.header-container {
  @include tablet {
    .headerDropdownButton {
      display: none !important;
    }
  }
}

.details-header {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2rem;
  color: $grey;
  margin-bottom: 1rem;
}

.show-child-on-hover {
  .toggle-child {
    margin-left: .6rem;
    visibility: hidden;
    display: inline-block;

    @include tablet {
      &:not(.show-on-mobile) {
        display: none;
      }

      &.show-on-mobile {
        visibility: visible;
      }
    }
  }

  &:hover {
    .toggle-child {
      visibility: visible;
    }
  }
}

.filter-input {
  height: 50px !important;
  min-height: unset !important;

  &.radio-input, &.checkbox-input {
    height: unset !important;
  }
}

.radio-input, .checkbox-input {
  height: unset !important;
  max-height: unset !important;
}

.sticky-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 2rem;
  right: 2rem;
  height: 96px;
  border-radius: 8px;
  background-color: #145685;
  box-shadow: 0 8px 20px 0 rgba(36,47,56,0.5);

  button {
    color: $action;
  }

  @include tablet {
    display: none;
  }
}

.sticky-actions {
  display: none;
  position: absolute !important;
  left: 0;
  right: 0;
  transition: all .3s ease-out;

  @include tablet {
    display: block;
  }
}

.paddingContainer {
  p {
    font-size: 1.4rem;
  }
}

.text-input.lohn-input {
  height: 83px;
  max-height: 83px;
  min-height: 83px;
  .validation-message {
    top: 89px;
  }
  input+div {
    top: -38px;
    height: calc(100% + 44px);
  }
}

/////////////

body {
	margin: 0;

  background-color: $lighter-grey;

	&.login {
		//background-image: url("../assets/imgs/background-1920w.jpg");
		//background-image: -webkit-image-set( url("../assets/imgs/background-710w.jpg") 0.5x, url("../assets/imgs/background-1024w.jpg") 0.75x, url("../assets/imgs/background-1920w.jpg") 1x, url("../assets/imgs/background-3840w.jpg") 2x );
		//background-size: cover;
		//background-repeat: no-repeat;

		#root {
			overflow: auto;
		}
	}

}

.card {
	border-radius: 8px;
	background-color: #FFFFFF;
	box-shadow: 0 18px 60px -30px rgba(38,50,56,0.2);
	padding: 3.2rem;
	width: 100%;
	max-width: 397px;
    @media (max-width: 575px) {
        padding: 22px;
    }
}

.dropdown input.textMaterial {
	// padding: 5px 10px;
	cursor: pointer;
}

.ReactModal__Overlay {
	z-index: 5;
	//background-color: var(--grey-light) !important;
}

.mdc-tab:focus {
    outline: 0
}

button.mdc-tab {
  .mdc-tab__text-label {
    display: flex;
    position: relative;
    align-items: center;
    opacity: 1 !important;

    &:after {
      opacity: 0;
      //content: "";
      color: $action;
      position: absolute;
      top: -6px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-radius: 4px;
      border-width: 2px;
      border-style: solid;
      transition: opacity .2s linear;
      box-shadow: 0 0 0 4px rgba(38, 50, 56, 0.1);
    }

    .count {
      display: block;
      color: white;
      line-height: 20px;
      font-size: 12px;
      min-width: 38px;
      height: 20px;
      margin-left: 8px;
      border-radius: 32px;
      background-color: $grey;
    }
  }

  &:focus {
    .mdc-tab__text-label {
      color: $action;
      &:after {
        opacity: 1;
      }
      .count {
        background-color: $action;
      }
    }
  }
}

button.mdc-tab {

}

.mdc-tab__ripple:hover::before, .mdc-tab__ripple.mdc-ripple-upgraded {
    opacity: 0;
}

.mdc-tab .mdc-tab__text-label {
    color: $grey;
}

.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
    width: 0 !important;
    opacity: 1 !important;
    transition: width .2s linear .2s;
  background-color: $highlight;
}

.mdc-tab.mdc-tab--active {

    .mdc-tab__text-label {
        color: $highlight;
      .count {
        background-color: $highlight;
      }
    }

  &:focus {
    color: $highlight;

    .mdc-tab__text-label {
      color: $highlight;
      &:after {
        color: $highlight;
      }
      .count {
        background-color: $highlight;
      }
    }
  }

    .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        width: 100% !important;
        max-width: 262px !important;
        transition: width .2s linear .2s;
        background-color: $highlight;
    }
}

.mdc-tab {

}

.mdc-tab--min-width {
    @media (max-width: 575px) {
        width: 50%;
    }
}

.dv-logo, .dv-logo-lite {
	display: none !important;
}

// Hide inpyt type=number native controls
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none !important;
    margin: 0  !important; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield  !important; /* Firefox */
}

.small-modal {
    position: absolute;
    word-wrap: break-word;
    width: 508px;
    background: #FFF;
    border: none;
    border-radius: 8px;
    padding: 24px;
    left: 50%;
    top: 50%;
    z-index: 13;
    -webkit-transform:translate(-50%,-50%) !important;
    -ms-transform:translate(-50%,-50%) !important;
    transform:translate(-50%,-50%) !important;
    -webkit-box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);
    -moz-box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);
    box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);

    @include tablet {
      width: 90vw;
    }

    .popup_content {
        &.loading {
            min-width: 287px;
            min-height: 255px;
        }
        &.confirm {
            min-width: 287px;
            min-height: 255px;
        }
    }

    &.csv-modal {
        width: 350px;
    }

    &.warnings-popup {
      width: 350px;
    }

    .progressBar {
        margin-top: 40px;
    }

    .timeRemaining {
        color: #37383C;
        font-size: 10px;
        letter-spacing: -0.13px;
        line-height: 22px;
        text-align: right;
        margin-top: 10px;
    }


    .buttons {
        display: flex;
        width: 72%;
        margin-left: auto;
        margin-top: -12px;

        button {
            text-transform: uppercase;
        }
    }

    .modal-buttons {
        margin-top: 40px;
    }

}

.small-modal-overlay {
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.large-modal {
    position: absolute;
    border: none;
    background: var(--grey-light);
    right: 0;
    top: 0;
    left: 0;
    z-index: 15;
    min-height: 100vh;
    transition: opacity .5s linear .5s;

    .modal {
        padding: 58px 22px;
        max-width: 980px;
        margin: auto;

      @include tablet {
        padding: 58px 0;
      }
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 85px;

        @media (max-width: 700px) {
            flex-wrap: wrap;
            margin-bottom: 30px;
        }

        @include tablet {
          padding: 0 20px;
        }
    }

    .modal-sub-header {
        display: flex;
        width: 100%;
        margin-bottom: 48px;

        @media (max-width: 700px) {
            flex-wrap: wrap;
            margin-bottom: 30px;
            overflow-x: scroll;
        }

      @include tablet {
        padding: 0 20px;
      }

    }

    .modal-header-left {
        height: 28px;
        color: #AFAFB1;
        font-family: Inter;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;

        @media (max-width: 700px) {
            margin-bottom: 30px;
        }
    }

    .modal-header-right {
        margin-left: auto;
        display: flex;
        height: 28px;
        color: #263238;
        font-family: Inter;
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 28px;

        @media (max-width: 700px) {
            flex-basis: 100%;
        }

        .column {
            margin-left: 20px;

            @media (max-width: 700px) {
                margin-left: 0;
                margin-right: 20px;
            }
        }

        .bold {
            font-weight: bold;
        }


    }

    .modal-sub-header-left {
        height: 22px;
        color: #263238;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;

        @media (max-width: 700px) {
            margin-bottom: 30px;
        }
    }

    .modal-sub-header-right {
        display: flex;
        margin-left: auto;
        height: 22px;
        color: #263238;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 700px) {
            flex-basis: 100%;
        }
    }

    .modal-content {
        transition: opacity .5s linear;

        &.form {
            padding: 48px;
            border-radius: 8px;
            background-color: #FFFFFF;
        }
        &.success {
            padding: 48px;
            border-radius: 8px;
            background-color: #FFFFFF;
        }


    }

    &.modal-fade-out {
        opacity: 0;

        .modal-content {
            opacity: 0;
        }
    }

    .modal-content {



        &.success {
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            width: calc(100% - 44px);
            max-width: 980px;

          @include tablet {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            transform: unset;
            padding: 2rem;
            &>div {
              position: absolute;
              top: 50%;
              left: 50%;
              -ms-transform: translateX(-50%) translateY(-50%);
              -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);

              width: calc(100% - 4rem);
              text-align: center;
            }
          }
        }
    }

    .modal-success-icon {
        position: relative;
        height: 72px;
        width: 72px;
        margin: auto;
        background-color: $verification;
        margin-bottom: 30px;
        border-radius: 36px;

        svg {
            position: absolute;
            top: 52%;
            left: 52%;
            transform: translate(-50%, -50%);
            height: 22px;
            width: 30px;
        }
    }

    .modal-success-title {
        color: #263238;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin-bottom: 28px;
    }

}

.large-modal-overlay {

}

.blank_row {
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    border: 2px dashed #AFAFB1;
    border-radius: 8px;
    line-height: 105px;
    margin: 40px auto 0 auto;

    color: #AFAFB1;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 100px;
    text-align: center;
}

.mobile-visible{
    display: none;
    @include tablet{
        display: unset;
    }
}
.mobile-hidden{
    display: unset;
    @include tablet{
        display: none;
    }
}

.redux-toastr .toastr {
  background-color: #242F38 !important;
  box-shadow: 0 6px 20px -5px rgba(36,47,56,0.35) !important;

  min-height: 5.2rem;
  padding: 1.6rem;

  opacity: 1;
  border-radius: .8rem;

  &.rrt-error {
    background-color: $error !important;
  }

  &.rrt-success {
    background-color: $verification !important;
  }

  .rrt-left-container {
    display: none;
  }

  .rrt-middle-container {
    margin: 0;
    padding: 0;
    width: 80%;
  }

  .rrt-right-container {
    margin: 0;
    padding: 0;
    width: 20%;
  }

  .rrt-title, .rrt-text {
    color: #FFFFFF;
    font-size: 1.4rem !important;
    font-weight: 300 !important;
    letter-spacing: 0;
    line-height: 2rem;
    margin-bottom: 0 !important;
  }

  .rrt-title {
    font-weight: bold !important;

    &+.rrt-text {
      margin-top: .5rem;
    }
  }

  .close-toastr {
    width: 20%;
    color: white;
    font-size: 3.2rem;
    font-weight: 300;
    opacity: .4;
    transition: opacity .1s linear;

    span {
      width: 2.4rem;
      right: 1.4rem;
      left: unset;
    }

    &:hover {
      opacity: .8;
    }
  }
}

.fade-in-animation {
  animation: fade-in-animation .8s;
}

@keyframes fade-in-animation {
  from { opacity: 0 }
  to { opacity: 1 }
}

.z-index-1000 {
  z-index: 1000;
}
