@import "../../../../styles/utils";

.personenExpandableRowTop {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    display: flex;
    max-width: 150px;
    align-items: center;
    padding: 0 .4rem;

    p {
      display: flex;
      align-items: center;
      word-wrap: break-word;
    }

    &:not(:first-child),
    &:not(:last-child) {
      padding: 0 .5rem;
    }

    &:first-child {
      max-width: 175px;

      p b {
        max-width: 175px;
        word-wrap: break-word;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      max-width: 170px;
    }

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }

    &:last-child {
      display: flex;
      align-items: center;
    }

    .mobileHeader {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    @include tablet {
      display: unset;
      max-width: unset !important;
      &:nth-child(1) {
        flex-basis: 100% !important;
      }

      &:last-child {
        margin-bottom: 0;
        justify-content: flex-end;
        flex-basis: 100% !important;
      }

      flex-basis: 50% !important;
      margin-bottom: 2.4rem;
    }
  }
}
