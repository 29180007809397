
.smallSpinner {
  width: 2.5rem;
  height: 2.5rem;


}

.loader {
  svg {
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
    display: block;

    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.cockpit {
  position: absolute;
  top: 4rem;

  left: 50%;
  transform: translateX(-50%);

  width: 5rem;
  height: 5rem;

  .loader {
    svg {
      width: 6.5rem;
      height: 6.5rem;
    }
  }
}



@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

