@import "../../styles/utils";

.headerContainer {
  display: flex;
  justify-content: space-between;

  height: 80px;
  margin-left: 80px;
  background-color: #FFFFFF;
  box-shadow: 0 18px 60px -30px rgba(36,47,56,0.2);

  @include tablet {
    margin-left: 0;

    &.hasMoreContent {
      height: 120px;
      flex-flow: wrap;
      align-items: center;
    }
  }
}

.rightContent {
  display: flex;
  align-items: center;
  padding-right: 3.2rem;
}

.headerTitle {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 82px;
  margin: 0;
  padding-left: 3.2rem;


  @include tablet {
    margin-left: 0;
    height: 60px;
    align-items: center;
  }
}

.headerContent {
  display: flex;
  justify-content: center;

  @include tablet {
    flex-basis: 100%;
  }
}

.headerContainer{
  &.hasMoreContent {
    .headerContent {
      @include tablet {
        flex-basis: 100%;
        order: 2;
      }
    }

    .rightContent {
      @include tablet {
        padding-top: 20px;
      }
    }

  }

  &.cockpit {
    .headerFilterButton {
      display: none;

      @include tablet {
        display: flex;
      }
    }
  }

  &.mutations {
    .headerContent {
      @include tablet {
        justify-content: unset;

        overflow: scroll;
        padding: 0 1rem;

        button {
          width: unset;
        }
      }
    }
  }
}
