.warningsModal {
  //position: relative;
}

.warningsModalCloseButton {
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  cursor: pointer;
}
