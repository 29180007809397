@import "../../../../styles/utils";

.invoicesExpandableRowTop {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  padding-top: 2.4rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2.4rem;

  .column {
    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }

    p {
      max-width: 180px;
      display: flex;
      align-items: center;
      word-wrap: break-word;
    }

    &:first-child {
      p {
        b {
          max-width: 180px;
          word-wrap: break-word;
        }
      }
    }

    .mobileHeader {
      display: none;
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: -0.15px;
      line-height: 1.8rem;
      margin-bottom: 0.4rem;

      @include tablet {
        display: block;
      }
    }

    @include tablet {
      &:nth-child(1) {
        flex-basis: 100%;
        margin-bottom: 2.4rem;
      }

      &:nth-child(2) {
        flex-basis: 50%;
        margin-bottom: 2.4rem;
      }
      &:nth-child(3) {
        flex-basis: 50%;
      }

      &:nth-child(6) {
        position: absolute;
        top: 2.4rem;
        right: 6.4rem;
      }
    }
  }
}
