@import "../../../../styles/utils";

.listView {
  margin-top: 3.2rem;

  @include tablet {
    margin-top: 4rem;
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
  margin-bottom: 2.4rem;

  @include tablet {
    display: none;
  }

  .column {
    display: flex;
    align-items: center;
    max-width: 150px;
    white-space: nowrap;

    &:nth-child(2) {
      max-width: 330px;
      display: flex;

      &> span {
        flex-basis: 50%;
      }
    }

    color: $grey;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0;

    &:not(:last-child) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
