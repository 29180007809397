.label {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.label-options {
    font-weight: 500;
    margin: 32px 0;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
}

.btn-line {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 4.8rem;
}

