@import "src/styles/utils";

.textInput {
   outline: none;
   padding: 5px 0;
   display: block;
   width: 100%;
   border: none;
   height: 50px;
   font-size: 16px;
   font-weight: 400;
   border-radius: 0;
   line-height: 16px;
   color: $grey;
   background: blue;
   letter-spacing: -0.2px;
   border-bottom: 2px solid $grey;
   transition: border-bottom linear 0.2s;


   &:hover {
      border-bottom: 2px solid $action-darker;
   }

   &:focus, &.hasValue {
      color: $content;
   }

   &:focus, &:active {
      border-bottom: 2px solid $highlight;
   }

   &.hasValue {
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   &.pr20 {
      padding-right: 20px !important;
   }

   &.pl40{
      padding-left: 40px !important;
   }

   &::placeholder {
      color: $grey;
      opacity: 1; /* Firefox */
      font-size: 16px;
   }

   &.disabled {
      color: $medium-grey;
      border-color: $medium-grey;

      &::placeholder {
         color: $medium-grey;
      }
   }
}

.textInput + div {
   opacity: 0;
   position: absolute;
   top: -25px;
   left: -6px;
   width: calc(100% + 12px);
   height: calc(100% + 28px);
   border: 2px solid $action-darker;
   border-radius: 4px;
   box-shadow: 0 0 0 4px rgba(71, 136, 177, 0.1);
   pointer-events: none;
}

.textInput + .dropdown {
   height: calc(100% + 33px);
   top: -27px;
}

.textInput + .filtering,
.textInput + .language {
   top: -6px;
   left: -10px;
   width: calc(100% + 20px);
   height: calc(100% + 14px);
}

.textInput:focus:not(:active) + div {
   //opacity: 1;
}

.textInput + label {
   left: 0;
   top: 19px;
   padding: 0;
   height: 17px;
   font-size: 14px;
   font-weight: 500;
   line-height: 17px;
   position: absolute;
   color: #AFAFB1;
   pointer-events: none;
   transition: 200ms ease all;
}

.textInputContainer {
   width: 100%;
   position: relative;

   svg {
      position: absolute;
      top: 15px;
      right: 0;
   }

   &.inputPrefix {
      position: absolute;
      top: 11px;
      left: 0;
   }

   &.disabledContainer {
      .calendar {
         rect, path {
            stroke: $medium-grey;
         }
      }
   }
}

.textInputContainer + .error {
   color: red;
}

.greenCheckBox {
   top: 20px !important;
}

.greenCheckBoxRight {
   right: 44px !important;
}
