.drawerContainer {
    overflow: auto;
    overflow-x: hidden;
    display: inline-flex;
    flex: 1 0 auto;
    width: 0;
    transition: all 0.3s ease-out 0s;
    background-color: #FFFFFF;
    height: 100%;
    // padding-bottom: 140px;
    //padding-bottom: 60px;
    padding-top: 128px;
    height: calc(100vh - var(--header-height));
    z-index: 11;
    max-height: 100vh;

    &.opened {
        width: 335px;
        max-width: 335px;
        right: 0;
    }

    @media (max-width: 1279px) {
        position: absolute;
        right: -335px;
        max-height: 100vh;

        &.opened {
            right: 0;
        }
        right: 0;
    }

    @media (max-width: 575px) {
        //max-height: calc(100vh - 180px);
    }

    @media (max-width: 440px) {

        &.opened {
            width: 100%;
            max-width: initial;
        }
    }


    .drawerContent {
        // position: fixed;
        // right: 0;
        // height: 100vh;
        // padding: 120px 0;
        height: 100%;
        width: 100%;
        min-width: 335px;
        background-color: #FFFFFF;
    }
}

body.invoicesPage .drawerContainer {
    max-height: calc(100vh - 0px);
    // max-height: calc(100vh - 142px);
}

.sidebar-header {
    color: #AFAFB1;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: left;
}
