@import "../../../../styles/utils";

.deletedMutationExpandableRowBottom {
  padding: 2.2rem 3.2rem;

    .headerText {
      color: $grey;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;
      margin-bottom: .4rem;
    }
}
