@import "../../styles/utils";

.cockpit-columns {
  display: flex;

  @include larger-tablet {
    flex-direction: column;
  }

  .cockpit-column {
    position: relative;
    flex: 1 1 0;
    margin-bottom: 3.2rem;

    &:not(:last-child) {
      margin-right: 4.2rem;

      @include larger-tablet {
        margin-right: 0;
      }
    }

    .cockpit-column-header {
      position: relative;
      min-height: 17.4rem;
      margin-bottom: 2.4rem;

      span {
        font-size: 1.4rem;
        letter-spacing: -0.17px;
        line-height: 2rem;
      }

      button {
        position: absolute;
        bottom: 0;
        max-width: 400px;
      }

      @include larger-tablet {
        padding: 0 2.2rem;
        min-height: unset;

        button {
          display: none;
        }
      }
    }

    .cockpit-column-content {
      position: relative;
      @include larger-tablet {
        min-height: 13.5rem;
      }
    }

    .cockpit-column-footer {
      display: flex;

      @include larger-tablet {
        padding: 0 2.2rem 3.2rem 2.2rem;
        justify-content: center;
        flex-direction: column;
      }

      .cockpit-column-mobile-button {
        display: none;

        @include larger-tablet {
          display: flex;
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}

.cockpit-employer-filter-input {
  width: 24rem;
  margin-left: auto;
  margin-bottom: 5.6rem;

  &>div {
    margin: 0;
  }

  @include tablet {
    display: none;
  }
}

.cockpit-button-text {
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.disabled-cockpit-button {
  svg {
    path, polyline {
      stroke: $grey;
    }
  }
}
