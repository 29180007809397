@import "../../styles/utils";

.stickyButton {
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;

   position: sticky;
   bottom: 2rem;
   right: 2rem;
   //height: 9.6rem;

   padding: 2rem;
   margin-left: 2rem;
   margin-right: 2rem;

   z-index: 13;
   border-radius: .8rem;
   background-color: #145685;
   box-shadow: 0 .8rem 2rem 0 rgba(36,47,56,0.5);
   transition: all .3s ease-in;

   @include tablet {
      position: fixed;
      width: 100%;
      margin: 0;
      left: 0;
      right: 0;
      bottom: -18.2rem;

      //height: 18.2rem;
      flex-wrap: nowrap;
      flex-direction: column;
      border-radius: 0;

      &.disabled {
         bottom: -23.9rem;
      }
   }

   &.expanded {
      bottom: 0;
      z-index: 14;
   }

   &.disabled {

   }
}

.stickyButtonButton {
   @include tablet {
      width: 100%;
      justify-content: center !important;
      div {
         display: none !important;
      }
   }
}

.stickyButtonFloatingButton {
   display: none;
   position: absolute;
   left: 2rem;
   height: 5.6rem;
   padding: 0 2rem;
   width: 24rem;

   color: white;
   font-size: 1.4rem;
   font-weight: 500;
   letter-spacing: 0;
   line-height: 2rem;

   outline: none;
   border: none;
   border-radius: 2.8rem;
   background-color: $action;
   box-shadow: 0 .6rem 2rem 0 rgba(36,47,56,0.5);
   transition: width .2s linear;

   span {
      max-width: 16.5rem;
      margin-right: 2rem;
      transition: opacity .3s ease-out, max-width .1s linear .1s;
   }

   svg {
      path, polyline {
         stroke: white;
      }
   }

   &.scrolled {
      width: 5.6rem;
      padding: 1.5rem;
      span {
         max-width: 0;
         margin: 0;
         opacity: 0;
         overflow: hidden;
      }
   }

   @include tablet {
      display: flex;
      align-items: center;
      justify-content: center;
   }
}

.stickyButtonTabs {
   display: flex;

   @include tablet {
      width: 100%;
      margin-bottom: 3.6rem;
      justify-content: space-between;
   }
}

.stickyButtonTab {
   position: relative;
   display: flex;
   align-items: center;
   margin-right: 2.4rem;

   font-weight: bold;
   text-transform: uppercase;

   @include tablet {
      margin-right: 0;
      flex-direction: column;
   }

   .label {
      color: white;
      font-size: 1.4rem;
      margin-right: 1.5rem;

      @include tablet {
         margin-right: 0;
      }
   }

   .count {
      color: $action;
      min-width: 3rem;
      font-size: 1.2rem;
      text-align: center;
      padding: .2rem .7rem;
      border-radius: 1.2rem;
      background-color: white;

      @include tablet {
         order: -1;
         margin-bottom: .8rem;
      }
   }

   //&:before {
   //   content: "";
   //   position: absolute;
   //   width: 100%;
   //   bottom: -1.2rem;
   //   height: .2rem;
   //   background-color: white;
   //}
}

.filtersRow {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   flex-basis: 100%;

   margin-top: 2.4rem;

   color: white;
   font-size: 1.4rem;
   line-height: 1rem;

   button {
      margin-left: 1.6rem;
      color: white !important;

      @include tablet {
         margin-left: auto;
         margin-top: .6rem;
      }
   }

   @include tablet {
      width: 100%;
      text-align: left;
      flex-direction: column;

      span {
         margin-right: auto;
      }
   }
}

.disabled {
   .stickyButtonButton {
      opacity: .5;
      pointer-events: none;
      cursor: not-allowed;
   }
   .stickyButtonTabs {
      opacity: .5;
   }
}
