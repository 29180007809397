.checkbox-input {
  label:nth-of-type(2) {
    margin-top: 1rem;
  }
}

.labelContent {
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 400;
    font-size: 18px;
  }
}