@import "../../styles/utils";

.card {
    //margin-top: 150px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
    .card {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100% !important;
        border-radius: 0 !important;
        // max-height: 64vh;
        overflow: auto;

        transform: unset;
        top: unset;


    }

    .topCard {
        position: absolute;
        top: -120px;
        right: 20px;
        width: 180px !important;
        padding: 10px !important;
        height: 71px;
        left: auto;
        border-radius: 8px !important;

        img {
            height: 100%;
            width: auto;
        }
    }
}

//@media (min-width: 1100px) and (max-width: 1600px) {
//    div.card {
//        margin-right: 20vw !important;
//    }
//}

//@media (min-width: 1100px) and (max-width: 1220px) {
//    div.card {
//        margin-right: 12vw !important;
//    }
//}


.passwordVergessenButton {
    height: 35px;
}

.btnMaxHeight {
    max-height: 52px;
}

.icon-show-password {
    width: auto;
    height: 18px;
    position: absolute;
    right: 0;
    bottom: 19px;
    z-index: 9999;
    cursor: pointer;

    transform: scale(.9);

    &.gray {
            path, circle {
                stroke: $medium-grey;
            }
    }
}

.login-container {
    position: relative;
    height: 100vh;
    //background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.pd-badge {
    position: absolute;
    left: 30px;
    top: 30px;
    height: 45px;

    @media (max-width: 575px) {
        display: none;
    }
}

.icr-badge {
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 58px;

    @media (max-width: 575px) {
        display: none;
    }
}

.login-logo {
    @media (max-width: 575px) {
        position: fixed;
        top: 4rem;
        right: 2.2rem;
        border-radius: .8rem;
        background-color: white;
        padding: .6rem 1.2rem 1.2rem 1.2rem;
    }

    svg {
        max-height: 48px;
        max-width: 130px;
    }
}

.login-title {
    height: 22px;
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    text-align: left;

    margin-top: 50px;
    margin-bottom: 40px;

    @media (max-width: 576px) {
        margin-top: 20px;
    }
}

.login-subcontent {
    margin-top: 4px;
}

.new-password-button {
  margin-top: 50px;
}

.icon-show-password {
    //&.dark {
    //    path {
    //        fill: var(--main) !important;
    //    }
    //}
}

.new-password-input {
    margin-bottom: 20px;
    .validationMessage {
        margin-bottom: -15px;
    }
}
