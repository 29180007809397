@import "../../../../styles/utils";

.cockpitInvoiceCard {
  position: relative;
  outline: none;
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  border-radius: .8rem;
  background-color: #FFFFFF;
  box-shadow: 0 1.8rem 6rem -3rem rgba(38,50,56,0.2);
  transition: all .2s linear;

  @include tablet {
    border-radius: 0;
  }

  &:hover {
    box-shadow: 0 2.7rem 10rem -2rem rgba(38, 50, 56, 0.2),
    0 .4rem 1.5rem -1rem rgba(38, 50, 56, 0.2),
    0 .9rem 3.0rem -1.5rem rgba(38, 50, 56, 0.2),
    0 1.8rem 6rem -3rem rgba(38, 50, 56, 0.2);
  }

  &:focus {
    &:after {
      opacity: 1;
    }
  }

  &:active {
    &:after {
      opacity: 0;
    }

    box-shadow: 0 .4rem 1rem -.8rem rgba(38, 50, 56, 0.6);
  }

  &:after {
    opacity: 0;
    //content: "";
    top: -.6rem;
    left: -.6rem;
    position: absolute;
    border-radius: 1.2rem;
    pointer-events: none;
    width: calc(100% + 1.2rem);
    height: calc(100% + 1.2rem);
    transition: opacity .2s linear;
    border: .2rem solid $action;
    box-shadow: 0 0 0 6px rgba($action-darker, 0.1);

    @include tablet {
      border-radius: 0;
    }
  }
}

.cockpitInvoiceCardButtons {
  display: flex;
  //align-items: center;

  .pdfIcon {
    margin-left: 1.6rem;
  }
}
