.alert-wrapper {
  background-color: #242f38;
  border-radius: 8px;
  position: absolute;
  z-index: 13;
  top: 2.8rem;
  left: 3.1rem;
  right: 4.7rem;
  height: 48px;
  padding: 0.75rem;
  gap: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.alert-label {
  color: #ffffff;
  font-size: 14px;
  margin-left: 32px;
  line-height: 20px;
  font-weight: 500;
}

.alert-button {
  border: 0;
  background-color: transparent;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  transition: filter 0.3s;
  &:hover {
    filter: brightness(0.85);
  }
}

.alert-icon-wrapper {
  & * {
    transition: filter 0.3s;
  }
  svg:hover {
    filter: brightness(0.85);
  }
}

.alert-button-box {
  gap: 1.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;

  svg {
    cursor: pointer;
  }
}
