.modalContent {
    width: 100%;
    margin: auto;
    max-width: 980px;
    margin-top: 16px;
    margin-bottom: 16px;
    // margin-bottom: 64px;
    // iPhone fix for empty buttons

    @media (max-width: 575px) {
        height: 100%
    }

;

    transform: translateZ(1px);

    h1 {
        color: var(--main);
        font-weight: 600;
        margin-bottom: 52px;

        @media (max-width: 991px) {
            margin-bottom: 32px;
        }
    }

    .modalSubHeader {
        margin-top: 85px;
        display: flex;
        flex-direction: row;

        @media (max-width: 991px) {
            flex-direction: column;
            margin-bottom: 0px;
            margin-top: 52px;
        }

        @media (max-width: 575px) {
            overflow-x: hidden;
        }
    }

    .modalHeader {
        display: flex;
        position: relative;
        // position: sticky;
        // top: 25px;
        margin-bottom: 32px;
        overflow: hidden;

        // background-color: var(--grey-light);
        @media (max-width: 991px) {
            // overflow-x: auto;
            flex-direction: column;

            &:after {
                background-image: linear-gradient(to left, #f3f3f3, rgba(255, 255, 255, 0.1));
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                transition: left ease-in-out .6s;
                left: 25%;
                top: 20px;
            }
        }

        .column {
            font-size: 1.6rem;

            &:first-of-type {
                font-weight: 600;
            }
        }
    }

    .title {
        font-weight: 500;
        line-height: 17px;
        margin-bottom: 42px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
}

.modalContentWarpper {
    border-radius: 8px;
    background-color: white;
    padding: 48px;

    @media (max-width: 991px) {
        padding: 32px;
    }
}

.uploadPage {
    .small-modal {
        width: 287px;
        height: 255px;
    }
}


.popup_title {
    width: 155px;
    color: #263238;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 16px;
}

.popup_text {
    width: 195px;
    color: #37383C;
    font-size: 14px;
    letter-spacing: -0.17px;
    line-height: 22px;
}

.popup_progress {
    width: 100%;
    height: 10px;
    background-color: var(--grey-light);
    margin-top: 40px;
    border-radius: 8px;

    .popup_progress_filled {
        width: 60%;
        height: 10px;
        background-color: var(--green-light);
        border-radius: 8px;
    }
}

.popup_remaining {
    margin-top: 10px;
    text-align: right;
    color: #37383C;
    font-family: Inter;
    font-size: 10px;
    letter-spacing: -0.13px;
    line-height: 22px;
}

.popup_spinner {
    width: 72px;
    margin: auto;
    margin-top: 42px;

    svg {
        animation-name: spin;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@media(min-width: 768px) {
    .modalNavigationContainer {
        button:nth-child(1) {
            margin-left: auto;
            margin-top: 1.5rem;
        }
        button:nth-child(2) {
            max-width: 175px;
            margin-top: 1.5rem;
        }
    }
}

.inputWrapper.high {
    margin-top: 3.5rem !important;
}

.path-3 {
    height: 70px;
    width: 72px;
    background: linear-gradient(230.31deg, rgba(167,184,113,0) 0%, #C4D787 100%);
}

.loader {
    font-size: 10px;
    margin: 43px auto;
    text-indent: -9999em;
    width: 7.5em;
    height: 7.5em;
    border-radius: 50%;
    background: var(--green-light);
    background: -moz-linear-gradient(left, var(--green-light) 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, var(--green-light) 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, var(--green-light) 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, var(--green-light) 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, var(--green-light) 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: var(--green-light);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: #FFFFFF;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.file-transfer-tooltip {
        height: 86px;
        width: 374px;
        border-radius: 8px;
        background-color: #263238;
        box-shadow: 0 27px 100px -20px rgba(38,50,56,0.2),
                    0 4px 15px -10px rgba(38,50,56,0.2),
                    0 9px 30px -15px rgba(38,50,56,0.2),
                    0 18px 60px -30px rgba(38,50,56,0.2);
}


.expandable-row-type {
    font-size: 1rem;
    color: #263238
}

.expandable-row-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #263238
}
.expandable-row-title-details{
    font-weight: normal;
}
.expandable-row-content .column .subtitle-top {
    width: calc(100% + 15rem);
    margin-top: -1.5rem;
}

.file-upload-expandable-row > :first-child{
    min-height: 9.8rem;
    padding-top: 15px;
}
.file-upload-expandable-row > :first-child > :nth-child(2){
    margin-top: 1rem;
}

.pdf-icon{
    border: none;
    background-color: transparent;
}

.sidebar-clear-button-wrapper {
    margin-left: auto;
    width: fit-content;
}
.sidebar-clear-button-wrapper button{
    font-size: 1.2rem;
}

.upload-modal-back-icon g{
    stroke: #AFAFB1;
    transition: 0.1s stroke;
}
.upload-modal-back-icon-wrapper:hover g{
    stroke: black;
}

.download-spinner{
    padding-top: 9rem;
    position: relative;
}
