@import "../../styles/utils";

.processingMutations {
  display: flex;
  align-items: center;

  color: $content;
  font-size: 1.2rem;
  line-height: 1.8rem;

  .text::after {
    content: "Mutationen in Verarbeitung";
    @include tablet {
      content: "in Verarbeitung";
    }
  };

  .spinner {
    margin: 0 .8rem;

    width: 1.6rem;
    height: 1.6rem;

    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
