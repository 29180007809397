@import '../../../styles/utils';

.radio {
   width: 1px;
   height: 1px;
   display: none;
}

.label {
   display: flex;
   align-items: flex-start;
   position: relative;
   padding: 0;
   cursor: pointer;
   width: auto;
   max-width: 100%;
   white-space: pre-wrap;
   height: auto;
   max-height: 100%;
   color: $content;
   font-size: 14px;
   letter-spacing: -0.17px;
   line-height: 1.6rem;
   outline: 0;
   transition: all .2s linear;

   &:before {
      content: "";
      opacity: 0;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 2px;
      border: 2px solid $action;
      box-shadow: 0 0 0 4px rgba($action, 0.1);
   }

   &:focus, &:hover {
      .circle {
         border-color: $action;
      }
   }

   &.checked {
      .circle {
         border-color: $highlight;

         .innerCircle {
            opacity: 1;
         }
      }

      &:before {
         border: 2px solid $highlight;
      }
   }
}

.circle {
   position: relative;
   height: 18px;
   min-height: 18px;
   width: 18px;
   min-width: 18px;
   margin-top: -1px;
   border: 2px solid $grey;
   border-radius: 9px;

   &.hasLabel{
      margin-right: 10px;
   }

   .innerCircle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 10px;
      height: 10px;
      border-radius: 5px;

      opacity: 0;
      background-color: $highlight;
   }
}
