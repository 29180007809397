@import "../../styles/utils";

.label {
    display: block;
    position: relative;
    height: 72px;
    padding: 18px 30px;
    border-radius: 8px;
    background-color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 18px 60px -30px rgba(1,50,62,0.2);
    margin-bottom: 24px;
}

.input {
    height: 1px;
    width: 1px;
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:focus {
        + .checkMark {
            border: 2px solid #263238 !important;
        }
        + .checkMark:before {
            opacity: 1;
            border: 2px solid #263238 !important;
        }
    }
}

.text {
    height: 36px;
    color: #263238;
    font-family: Inter;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 36px;
}

.checkMark {
    border-radius: 13px;
    position: absolute;
    right: 30px;
    top: 25px;
    height: 26px;
    width: 26px;
    border: 2px solid $action;

    &:before {
        content: "";
        opacity: 0;
        height: 36px;
        width: 36px;
        position: absolute;
        top: -7px;
        left: -7px;
        border-radius: 18px;
        border: 2px solid $action-darker;
        box-shadow: 0px 0px 0px 4px rgba($action, 0.1);
    }

}

.label input:checked ~ .checkMark {
    background: url('../../assets/icons/checkmark-bold.svg') center / contain no-repeat;
    background-size: 10px;
    opacity: 1;
    border: 2px solid $action;

}
